import React, { useEffect, useState } from "react";

import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

const annotation = {
  type: "line",
  borderColor: "black",
  borderDash: [],
  borderDashOffset: 0,
  borderWidth: 1,
  label: {
    enabled: true,
    content: (ctx) => "Average: " + 50,
    position: "end",
  },
  scaleID: "y",
  value: (ctx) => 50,
};
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    annotation: {
      annotations: {
        annotation,
      },
    },
  },
};

const labels = [
  "14:30",
  "14:40",
  "14:50",
  "15:00",
  "15:10",
  "15:20",
  "15:30",
  "15:40",
  "15:50",
];

const COLOR = "#" + ((Math.random() * 0xffffff) << 0).toString(16);

const getFakeData = () => ({
  labels,
  datasets: [null].map((item) => {
    return {
      fill: true,
      label: ``,
      data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
      borderColor: COLOR,
      backgroundColor: COLOR + "65",
    };
  }),
});

export const ActiveClassroomIndex = ({ statDuration }) => {
  const [data, setData] = useState(getFakeData());

  useEffect(() => {
    setData(getFakeData());
  }, [statDuration]);

  return (
    <div className="row p-0">
      <div className="mt-2">
        <p className="ff-inter text-wmg fw-500 fs-20 mb-3">
          Calories burnt (estimated)
        </p>
        <Line options={options} data={data} />
      </div>
    </div>
  );
};
