import { useEffect, useState } from "react";
import Loader from "../../components/loader/loader";
import { APIS, BASE_URL } from "../../constants/appConstants";
import { fetchPost } from "../../utils/fetchPost";

export const ManageUsers = ({ setInitData, user_id, school_id }) => {
  const [entirePageLoading, setEntirePageLoading] = useState(true);

  useEffect(() => {
    setEntirePageLoading(true);
    fetchPost(BASE_URL + APIS.INITIAL_API_BACKOFFICE, "post", {
      school_id,
      user_id,
    })
      .then((res) => {
        setInitData(res);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setEntirePageLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {entirePageLoading && (
        <div
          className="d-flex position-fixed w-100 h-100 bg-white align-items-center justify-content-center"
          style={{ zIndex: 999 }}
        >
          <Loader />
        </div>
      )}
      <div>sadad</div>
    </>
  );
};
