import { useDocumentTitle } from "../../hooks/useDocumentTitle";

const BASE_URL = "https://app.class-proxima.com/";

const LINKS = [
  {
    label: "All Comments",
    link: BASE_URL + `comments`,
  },
  {
    label: "Webview Usage",
    link: BASE_URL + `webview-usage`,
  },
  {
    label: "Monitoring Dashboard (Pipelines)",
    link: "http://monitor.class-proxima.com/",
  },
  {
    label: "Dashboard",
    link: BASE_URL + `dashboard`,
  },
];

export const LinksListing = () => {
  useDocumentTitle("Links");

  return (
    <div className="container p-0">
      <div className="d-flex p-2 justify-content-between">
        <p className="m-0 text-center w-100 fs-20 fw-500">Important Links</p>
      </div>
      <div className="row p-2">
        <div className="col-11 col-md-4 mx-auto">
          {LINKS?.map((item) => (
            <div
              onClick={() => {
                window.open(item?.link, "_blank");
              }}
              key={item?.label}
              className="border rounded my-3 p-2 pointer bg-success shadow-sm user-select-none"
            >
              <p className="m-0 fw-600 fs-16 text-center text-white">
                {item?.label}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
