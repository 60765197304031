import { Route, Switch } from "react-router-dom";
import { Home } from "../pages/home/home";
import { Login } from "../pages/login/login";
import { Dashboard } from "../pages/dashboard/dashboard";
import { AllCommentsListing } from "../pages/internal-dashboard/AllCommentsListing";
import { LinksListing } from "../pages/internal-dashboard/LinksListing";
import { WebviewUsage } from "../pages/internal-dashboard/WebviewUsage";
import { WebviewUsageListing } from "../pages/internal-dashboard/WebviewUsageListing";
import NotFound from "../pages/not-found/not-found";
import { RoleBasedPage } from "../pages/role-based-page/role-based-page";
import SMFeeds from "../pages/sm-feeds/sm-feeds";
import SpecificFeed from "../pages/specific-feed/specific-feed";
import { AlertReport } from "../pages/alert-report/alert-report";
import { ActivityLogs } from "../pages/activity-logs/activity-logs";
import { DocChatBot } from "../pages/doc-chatbot-demo/doc-chatbot-demo";
import { DeleteAccount } from "../pages/voice-gpt-app-pages/delete-account";

const AppRoutes = () => {
  return (
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/login" component={Login} exact />
      <Route
        path={["/webview-usage/:user_id"]}
        component={WebviewUsage}
        exact
      />
      <Route path={["/feed/:feedId"]} component={SpecificFeed} exact />
      <Route
        path={["/:school_id/:user_id", "/:school_id/:user_id/:page_name"]}
        component={RoleBasedPage}
        exact
      />
      <Route
        path={["/e2a1fea7-818a-45f6-96e9-271a05930a33"]}
        component={SMFeeds}
        exact
      />{" "}
      <Route path={"/activity-logs"} component={ActivityLogs} exact />
      <Route path={["/dashboard"]} component={Dashboard} exact />
      <Route path={["/webview-usage"]} component={WebviewUsageListing} exact />
      <Route path={["/comments"]} component={AllCommentsListing} exact />
      <Route path={["/links"]} component={LinksListing} exact />
      <Route path={["/not-found"]} component={NotFound} exact />
      <Route path={["/report"]} component={AlertReport} exact />
      <Route path={["/chatbot"]} component={DocChatBot} exact />
      <Route path={["/account-delete"]} component={DeleteAccount} exact />
    </Switch>
  );
};

export default AppRoutes;
