import { useEffect, useState } from "react";
import Comments from "../../components/comments/comments";
import Loader from "../../components/loader/loader";
import VideoCard from "../../components/video-card/video-card";
import { APIS, BASE_URL } from "../../constants/appConstants";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { fetchGet } from "../../utils/fetchGet";

const SpecificFeed = (props) => {
  const { role } = props;
  const { feedId } = props?.match?.params;
  const { height } = useWindowDimensions();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const loadData = () => {
    setLoading(true);
    fetchGet(BASE_URL + `${APIS.GET_FEED_BY_ID}?id=${feedId}`)
      .then((res) => {
        setItem(res?.data?.data?.item[0]);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && (
        <div
          className="position-fixed top-0 end-0 start-0 bottom-0 bg-white d-flex align-items-center justify-content-center"
          style={{
            zIndex: 999,
          }}
        >
          <Loader />
        </div>
      )}
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 mx-auto">
            {item && (
              <div className="my-5">
                <VideoCard height={height * 0.7} item={item} />
              </div>
            )}
            <div className="py-3">
              {item && <Comments role={role} feedId={feedId} />}{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecificFeed;
