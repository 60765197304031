import { useState, useEffect } from "react";
import Loader from "../../components/loader/loader";
import VideoCard from "../../components/video-card/video-card";
import { APIS, BASE_URL } from "../../constants/appConstants";
import { fetchGet } from "../../utils/fetchGet";
import Moment from "react-moment";
import { fetchPost } from "../../utils/fetchPost";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "./teachers-view.css";
import { correctTime } from "../../utils/date";
const ACCEPT_BTN = {
  null: "bg-cprimary-transparent",
  0: "bg-cprimary-transparent",
  1: "bg-cprimary",
};

const REJECT_BTN = {
  null: "bg-danger-transparent",
  1: "bg-faulters-transparent",
  0: "bg-danger",
};

const TeachersView = ({ userData }) => {
  const [feeds, setFeeds] = useState(null);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [stopFetching, setStopFetching] = useState(false);
  const { height } = useWindowDimensions();

  const checkHeader = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (clientHeight + scrollTop >= scrollHeight - 5) {
      setOffset((prev) => prev + 10);
    }
  };

  useEffect(() => {
    onClientEntry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (feeds && feeds?.length % 10 !== 0) return;

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  const onClientEntry = () => {
    window.addEventListener("scroll", checkHeader);
  };
  const loadData = () => {
    if (stopFetching) return;
    setLoading(true);
    fetchGet(BASE_URL + `${APIS.GET_FEED}?offset=${offset}`)
      .then((res) => {
        if (!feeds) {
          setFeeds(res?.data?.results);
        } else {
          setFeeds((prev) => prev.concat(res?.data?.results));
        }
        setStopFetching(res?.data?.results?.length === 0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const observeVideos = () => {
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry?.target?.play();
          entry.target.className =
            "feed-video border border-primary shadow trans-3s";
        } else {
          entry?.target?.pause();
          entry.target.className = "feed-video trans-3s";
        }
      });
    };
    const options = { threshold: 0.5 };
    const observer = new IntersectionObserver(callback, options);
    const videos = document.querySelectorAll(".feed-video");
    if (videos?.length === 0) return;
    videos?.forEach((vid) => {
      observer.observe(vid);
    });
  };

  useEffect(() => {
    observeVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeds]);

  const updateParentFeedUpdate = (value, item) => {
    fetchPost(BASE_URL + `${APIS.UPDATE_PARENT_FEED}`, "post", {
      id: item?.id,
      value,
      school_user_id: userData?.user_id,
      school_user_name: userData?.name,
    }).then((res) => {
      setFeeds((prev) =>
        prev?.map((item_) =>
          item_?.id === item?.id
            ? { ...item_, school_label: value ? 1 : 0 }
            : item_
        )
      );
    });
  };

  return (
    <>
      <div className="container">
        {/* <div
          className="position-fixed bottom-0 end-0 border rounded-circle m-3 m-md-5 d-flex align-items-center justify-content-center pointer bg-white shadow"
          style={{
            width: 65,
            height: 65,
            zIndex: 99,
          }}
        >
          <i class="bi fs-28 bi-chat-left-text fc-cprimary"></i>
        </div> */}
        <div className="row">
          <div className="mt-3 col-12 col-md-7 mx-auto">
            {feeds &&
              feeds?.map((item, index) => (
                <div
                  key={index}
                  className="position-relative mb-5 border-bottom"
                >
                  <div
                    style={{
                      height: height * 0.05,
                    }}
                    className="d-flex align-items-center"
                  >
                    <div className="m-0 ff-inter text-secondary fw-500 fs-15 m-0 mb-2 d-flex align-items-center">
                      <Moment fromNow>{correctTime(item?.addedOn)}</Moment>{" "}
                      <p className="mx-1"> </p>(
                      <Moment format="LLLL">
                        {correctTime(item?.addedOn)}
                      </Moment>
                      )
                    </div>
                  </div>
                  <VideoCard height={height * 0.9} item={item} />
                  <div className="position-absolute w-100 m-0 left-0 py-3 bottom-0 row align-items-center video-controls">
                    <div className="col-6 d-flex border-end align-items-center justify-content-center">
                      <button
                        type="button"
                        onClick={() => updateParentFeedUpdate(true, item)}
                        className={`btn ${
                          ACCEPT_BTN[item?.school_label]
                        } border-none px-5 shadow-none trans-3s border border-white`}
                      >
                        <p
                          className={`m-0 ff-inter fw-500 fs-14 text-white trans-3s`}
                        >
                          Accept
                        </p>
                      </button>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-center">
                      <button
                        onClick={() => updateParentFeedUpdate(false, item)}
                        type="button"
                        className={`btn ${
                          REJECT_BTN[item?.school_label]
                        } border-none px-5 shadow-none trans-3s border border-white`}
                      >
                        <p
                          className={`m-0 ff-inter fw-500 fs-14 text-white trans-3s`}
                        >
                          Reject
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            {feeds?.length === 0 && (
              <p className="m-0 text-secondary text-center">No Feed</p>
            )}
            {loading && (
              <div className="d-flex align-items-center justify-content-center">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeachersView;
