import { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { APIS, BASE_URL, LOG_TYPES } from "../../constants/appConstants";
import { AppContext } from "../../context/AppContext";
import { fetchGet } from "../../utils/fetchGet";
import Flatpickr from "react-flatpickr";
import { getFormattedDate } from "../../utils/date";
const DEF_VAL_PAGINATION = (date) => ({
  dateFilter: date,
  formattedDate: getFormattedDate(date),
});

const formatFilter = (paginationConfig) => {
  return `?log_date=${paginationConfig?.formattedDate}`;
};
export const ActivityLogs = () => {
  const { setLoading } = useContext(AppContext);
  const [logs, setLogs] = useState(null);
  const [paginationConfig, setPaginationConfig] = useState(
    DEF_VAL_PAGINATION(new Date())
  );
  const init = () => {
    setLoading(true);
    fetchGet(BASE_URL + APIS.GET_LOGS + formatFilter(paginationConfig))
      .then((res) => {
        console.log(res);
        setLogs(res?.data?.logs);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  return (
    <div className="container">
      <div className="row m-0 p-0 my-3">
        <div className="col-12 col-md-4 mt-4 m-0 p-0">
          <Flatpickr
            options={{
              dateFormat: "J F Y",
            }}
            data-enable-time
            value={paginationConfig?.dateFilter}
            onChange={(date) => {
              const splitted = date.toString()?.split(" ");
              const mon = (new Date(date).getMonth() + 1)
                ?.toString()
                ?.padStart(2, "0");
              const year = splitted[3];
              const day = splitted[2];
              setLogs(null);
              const formattedDate = `${year}-${mon}-${day}`;
              setPaginationConfig({
                dateFilter: date,
                formattedDate,
              });
            }}
            className="m-0 shadow-sm form-control ff-inter fw-500 fs-14"
            style={{
              color: "#000",
              fontSize: 15,
            }}
          />
        </div>
      </div>
      <div className="row">
        {logs?.length > 0 ? (
          <Table responsive striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>S. No.</th>
                <th>School ID</th>
                <th>School Name</th>
                <th>User Name</th>
                <th>User ID</th>
                <th>Acivity</th>
              </tr>
            </thead>
            <tbody>
              {logs?.map((log, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{log?.school_id}</td>
                  <td>{log?.school_name}</td>
                  <td>{log?.user_name}</td>
                  <td>{log?.user_id}</td>
                  <td>{LOG_TYPES[log?.log_type]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div>
            <p>No Logs Found</p>
          </div>
        )}
      </div>
    </div>
  );
};
