import React, { Component, createContext } from "react";

export const AppContext = createContext();

class AppContextProvider extends Component {
  state = {
    loading: false,
    user: null,
    userDbData: null,
    schoolsList: null,
  };

  setLoading = (value) => {
    this.setState({ loading: value });
  };

  setUser = (value) => {
    this.setState({ user: value });
  };

  setValueByKey = (key, value) => {
    this.setState({ [key]: value });
  };
  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          setLoading: this.setLoading,
          setUser: this.setUser,
          setValueByKey: this.setValueByKey,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default AppContextProvider;
