import { useState } from "react";
import { ActiveClassroomIndex } from "./active-classroom-index";
import { InteractionScore } from "./interaction-score";

const FEATURE_CHIP = [
  { key: "total_metrics", label: "Total Metrics" },
  { key: "active_classroom_index", label: "Calories burnt (estimated)" },
  { key: "interaction_score", label: "Interaction Score" },
  { key: "proactiveness_score", label: "Proactiveness Score" },
];

const STATS_DURATION = ["Daily", "Weekly", "Monthly"];

const START_TIME = ["10", "11", "12", "01", "02"];
const TEACHER = ["All", "Olivia", "Emma", "Charlotte", "Sophia", "Isabella"];
export const Dashboard = () => {
  const [currFeature, setCurrFeature] = useState(FEATURE_CHIP[2]?.key);
  const [showFilter, setShowFilter] = useState(false);
  const [statDuration, setStatDuration] = useState(STATS_DURATION[0]);
  const [startTime, setStartTime] = useState(START_TIME[0]);
  const [teacher, setTeacher] = useState(TEACHER);
  const renderFeatureBasedOnChip = () => {
    switch (currFeature) {
      case "interaction_score":
        return (
          <InteractionScore
            startTime={startTime}
            statDuration={statDuration}
            teacher={teacher}
          />
        );
      case "active_classroom_index":
        return <ActiveClassroomIndex statDuration={statDuration} />;
      default:
        return <div>Under Process</div>;
    }
  };

  const onChange = (e) => {
    setStatDuration(e.target.value);
  };
  const onTimeChange = (e) => {
    setStartTime(e.target.value);
  };

  const onTeacherChange = (e) => {
    if (e.target.value === "All") {
      setTeacher(TEACHER);
      return;
    }

    setTeacher([e.target.value]);
  };

  const resetFilter = () => {
    setStartTime(START_TIME[0]);
    setTeacher(TEACHER);
    setStatDuration(STATS_DURATION[0]);
  };

  return (
    <div className="container">
      <div className="row my-3 mb-4 flex-row g-1 g-md-3">
        {FEATURE_CHIP.map((item) => (
          <div key={item?.key} className="col-auto">
            <p
              onClick={() => setCurrFeature(item?.key)}
              className={`pointer user-select-none ff-inter my-1 my-md-0 px-3 m-0 fs-14 py-1 shadow-sm filter-chip ${
                currFeature === item?.key ? "filter-chip-active" : ""
              }`}
            >
              {item?.label}
            </p>
          </div>
        ))}
      </div>

      <div
        className={`row my-2 mb-3 trans-3s flex-row g-1 g-md-3 p-2 rounded ${
          showFilter ? "border-wmg" : ""
        }`}
      >
        <div className="d-flex m-0 flex-row align-items-center justify-content-between">
          <div
            onClick={() => setShowFilter(true)}
            className="pointer border py-1 px-2 rounded d-flex align-items-center"
          >
            <p className="m-0 user-select-none fs-15 me-1">Apply Filters</p>
            <i className="bi bi-funnel"></i>
          </div>
          {showFilter && (
            <div
              onClick={() => setShowFilter(false)}
              className="fs-20 text-wmg pointer"
            >
              <i className="bi bi-x-circle"></i>
            </div>
          )}
        </div>

        {showFilter && (
          <div className="row mt-4">
            <div className="col-6 d-flex align-items-center">
              <div className="row g-1 g-md-2">
                <div className="col-auto m-0">
                  <p className="m-0 mb-2 ff-inter fw-400 fs-14 ms-1">Stats:</p>
                  <select
                    onChange={onChange}
                    className="form-control shadow-none m-0 pe-5"
                  >
                    {STATS_DURATION?.map((item) => (
                      <option selected={item === statDuration}>{item}</option>
                    ))}
                  </select>
                </div>

                {currFeature === "interaction_score" && (
                  <div className="col-auto m-0">
                    <p className="m-0 mb-2 ff-inter fw-400 fs-14 ms-1">
                      Teacher:
                    </p>
                    <select
                      onChange={onTeacherChange}
                      className="form-control shadow-none m-0 pe-5"
                    >
                      {TEACHER?.map((item) => (
                        <option selected={item === teacher[0]}>{item}</option>
                      ))}
                    </select>
                  </div>
                )}

                {currFeature === "interaction_score" && (
                  <div className="col-auto m-0">
                    <p className="m-0 mb-2 ff-inter fw-400 fs-14 ms-1">
                      Start Time:
                    </p>
                    <select
                      onChange={onTimeChange}
                      className="form-control shadow-none m-0 pe-5"
                    >
                      {START_TIME?.map((item) => (
                        <option selected={item === startTime}>{item}</option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="row g-1 h-100 g-md-2 justify-content-end align-items-end">
                <div className="col-auto m-0 d-flex align-items-center">
                  <button
                    onClick={resetFilter}
                    className="btn btn-secondary px-5 ff-inter fs-15 shadow-none"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row m-0 mb-5">{renderFeatureBasedOnChip()}</div>
    </div>
  );
};
