import {
  totalApproved,
  totalRejected,
  totalVids,
} from "../../pages/superadmin-feeds/superadmin-feeds";
import Loader from "../loader/loader";

export const SuperAdminStats = ({
  allStats,
  userData,
  paginationConfig,
  stats,
}) => {
  return (
    <div className="row border m-0 rounded mt-3 border-primary">
      {allStats === null ? (
        <div className="d-flex align-items-center justify-content-center">
          <Loader />
        </div>
      ) : (
        <div className="col-12 p-2 px-3">
          <p className="m-0 text-primary fs-16 fw-600">Hi, {userData?.name}</p>

          <p className="m-0 text-dark fs-16 fw-400">
            Here are your labelling stats{" "}
            {paginationConfig?.formattedDate ? "for " : "till "}
            {paginationConfig?.dateFilter?.toString().substring(0, 15)},
          </p>
          <p className="m-0 my-1 text-dark fs-15 fw-600">
            Total Videos:{" "}
            <span className="text-primary fw-500"> {totalVids(stats)}</span>
          </p>

          <p className="m-0 my-1 text-dark fs-15 fw-600">
            Total Do this Videos:{" "}
            <span className="text-primary fw-500"> {totalApproved(stats)}</span>
          </p>

          <p className="m-0 my-1 text-dark fs-15 fw-600">
            Total Avoid this Videos:{" "}
            <span className="text-primary fw-500"> {totalRejected(stats)}</span>
          </p>

          <p className="m-0 my-1 text-dark fs-15 fw-600">
            Videos Liked :{" "}
            <span className="text-primary fw-500">
              {" "}
              {allStats?.like?.[0]?.count || 0}
            </span>
          </p>

          <p className="m-0 my-1 text-dark fs-15 fw-600">
            Videos Disliked :{" "}
            <span className="text-primary fw-500">
              {" "}
              {allStats?.dislike?.[0]?.count || 0}
            </span>
          </p>
        </div>
      )}
    </div>
  );
};
