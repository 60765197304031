import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { CPServices } from "../../components/cp-services/cp-services";
import { AppContext } from "../../context/AppContext";
import { firebase } from "../../firebase/config";

export const Home = () => {
  const { user, setUser, setValueByKey, userDbData } = useContext(AppContext);
  const history = useHistory();

  const logoutUser = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("USER_DATA");
        history.push("/login");
        setValueByKey("userDbData", null);
        setUser(null);
      });
  };

  return (
    <div className="container">
      <div className="row">
        <p className="fw-500 fs-24 text-center ff-inter m-0 my-4">
          Welcome to Class Proxima
        </p>

        <div className="d-flex align-items-center justify-content-center">
          {user ? (
            <div className="w-100">
              <p className="text-center fs-16 fw-500 m-0 ff-inter">
                You are Logged In as{" "}
                <span className="text-primary">{userDbData?.name}</span>
              </p>

              <div className="w-100 d-flex align-items-center justify-content-center">
                <button
                  onClick={logoutUser}
                  className="btn btn-secondary my-4 shadow-none"
                >
                  Logout
                </button>
              </div>
              <CPServices userDbData={userDbData} />
            </div>
          ) : (
            <div className="d-flex">
              <Link to="/login">
                <p className="fs-16 fw-500 m-0 ff-inter">Proceed to Login </p>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
