import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Loader from "../../components/loader/loader";
import { APIS, BASE_URL } from "../../constants/appConstants";
import { fetchGet } from "../../utils/fetchGet";

export const WebviewUsageListing = (props) => {
  const [allUserUsage, setAllUserUsage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");

  console.log(props);
  useEffect(() => {
    setLoading(true);
    fetchGet(BASE_URL + `${APIS.GET_ALL_USER_USAGE}`)
      .then((res) => {
        setAllUserUsage(res?.data?.results);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="container p-0">
      <p className="fw-500 ff-inter m-0 my-4 text-primary">Webview Usage</p>

      <div className="row m-0 mb-5">
        <input
          className="form-control m-0 shadow-none"
          placeholder="Search User"
          onChange={(e) => setQuery(e.target.value)}
          value={query}
        />
      </div>

      <div className="row m-0 d-flex flex-row">
        {allUserUsage &&
          allUserUsage
            ?.filter(
              (item) =>
                item?.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
                item?.username?.toLowerCase()?.includes(query?.toLowerCase())
            )
            ?.map((item) => (
              <Link
                className="col-auto ps-2 pe-5 shadow-sm border rounded my-2 py-2 fw-500 text-secondary me-md-4"
                to={`/webview-usage/${item?.user_id}`}
              >
                {item?.name} [{item?.count} times]
              </Link>
            ))}
      </div>

      {loading && (
        <div className="d-flex align-items-center justify-content-center py-4">
          <Loader />
        </div>
      )}
    </div>
  );
};
