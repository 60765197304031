import { useState } from "react";
import { ManageUsers } from "../manage-users/manage-users";
import NotFound from "../not-found/not-found";
import ShareableMoments from "../shareable-moments/shareable-moments";
import SMFeeds from "../sm-feeds/sm-feeds";
import SuperAdminFeeds from "../superadmin-feeds/superadmin-feeds";
import TeachersView from "../teachers-view/teachers-view";

export const RoleBasedPage = (props) => {
  const [initData, setInitData] = useState(null);

  const { school_id, user_id, page_name } = props?.match?.params;

  const RenderPage = (initData) => {
    if (initData?.error) return <NotFound />;
    switch (initData?.data?.role) {
      case "SUPER_USER":
        console.log(page_name);
        return page_name === "teacher" ? (
          <TeachersView
            role={initData?.data?.role}
            userData={initData?.data?.userData}
            school_id={school_id}
          />
        ) : page_name === "school-manager" ? (
          <SMFeeds
            role={initData?.data?.role}
            userData={initData?.data?.userData}
            school_id={school_id}
          />
        ) : !page_name ? (
          <SMFeeds
            role={initData?.data?.role}
            userData={initData?.data?.userData}
            school_id={school_id}
          />
        ) : (
          <NotFound />
        );

      case "SCHOOL_MANAGER":
        return !page_name ? (
          <SMFeeds
            role={initData?.data?.role}
            userData={initData?.data?.userData}
            school_id={school_id}
          />
        ) : (
          <NotFound />
        );

      case "TEACHER":
        return !page_name ? (
          <TeachersView
            role={initData?.data?.role}
            userData={initData?.data?.userData}
            school_id={school_id}
          />
        ) : (
          <NotFound />
        );

      case "SUPER_ADMIN":
        return !page_name ? (
          <SuperAdminFeeds
            userData={initData?.data?.userData}
            school_id={school_id}
            role={initData?.data?.role}
          />
        ) : page_name === "moments" ? (
          <ShareableMoments
            role={initData?.data?.role}
            userData={initData?.data?.userData}
            school_id={school_id}
          />
        ) : (
          <NotFound />
        );

      default:
        return <NotFound />;
    }
  };

  return (
    <>
      {initData ? (
        RenderPage(initData)
      ) : (
        <ManageUsers
          setInitData={setInitData}
          school_id={school_id}
          user_id={user_id}
        />
      )}
    </>
  );
};
