import { useEffect, useState } from "react";
import {
  APIS,
  BASE_URL,
  CURR_LABEL_INDICATOR,
  FEED_TYPE,
  SECTIONS,
} from "../../constants/appConstants";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import VideoCard from "../../components/video-card/video-card";
import { fetchPost } from "../../utils/fetchPost";
import toast from "react-hot-toast";
import { fetchGet } from "../../utils/fetchGet";
import { Modal } from "react-bootstrap";
import Comments from "../../components/comments/comments";
import { useMediaQuery } from "react-responsive";
import CAMERAS from "../../constants/camera_no_to_name_mapping.json";
import moment from "moment";
import Spinner from "../../assets/svgs/spinner2.svg";
import CircularProgress from "@mui/material/CircularProgress";

const FILTER_CHIPS = [
  { name: "All", value: "(1,-1,0)" },
  { name: "Accepted", value: "(1)" },
  { name: "Rejected", value: "(0)" },
  { name: "Not Yet Reviewed", value: "(-1)" },
];

const ACCEPT_BTN = {
  null: "bg-secondary",
  0: "bg-secondary",
  1: "bg-primary",
};

const REJECT_BTN = {
  null: "bg-secondary",
  1: "bg-secondary",
  0: "bg-danger",
};
const headerBtnsText = (headerBg, sectionName, btnName) => {
  if (headerBg && sectionName === btnName) {
    return "text-white bg-wmg";
  } else if (headerBg && sectionName !== btnName) {
    return "text-dark bg-none";
  } else if (!headerBg && sectionName === btnName) {
    return "text-white bg-wmg";
  } else if (!headerBg && sectionName !== btnName) {
    return "text-black bg-none";
  }
};

const highlightFeedType = (item, feedType) => {
  if (feedType === item?.key) {
    return "text-white bg-secondary";
  } else {
    return "text-black bg-none";
  }
};

const ShareableMoments = ({ userData, role, school_id }) => {
  const [headerBg, setHeaderBg] = useState(false);
  const [section, setSection] = useState(SECTIONS[0]);
  const [feedType, setFeedType] = useState(FEED_TYPE?.feed);
  const [feeds, setFeeds] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [filterChipVal, setFilterChipVal] = useState(FILTER_CHIPS[0].value);
  const { height } = useWindowDimensions();
  const [feedId, setFeedId] = useState(null);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const [localLoading, setLocalLoading] = useState(null);

  const updateTeacherFeed = (value, item) => {
    setLocalLoading(item?.id);
    fetchPost(BASE_URL + `${APIS.UPDATE_TEACHERS_FEED}`, "post", {
      id: item?.id,
      value,
      school_user_id: userData?.user_id,
      school_user_name: userData?.name,
      cloudfront_url: item?.post,
      timestamp: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
      currentValue: item["school_label"],
    })
      .then((res) => {
        setFeeds((prev) =>
          prev?.map((item_) =>
            item_?.id === item?.id
              ? {
                  ...item_,
                  school_label: value === null ? null : value ? 1 : 0,
                  school_user_id: userData?.user_id,
                  school_user_name: userData?.name,
                }
              : item_
          )
        );
      })
      .finally(() => {
        setLocalLoading(null);
      });
  };

  const updateShowVideoToParent = (e, item, value) => {
    setLocalLoading(item?.id);
    e.target.classList.toggle("active");
    fetchPost(BASE_URL + `${APIS.UPDATE_SHOW_FEED_TO_PARENT}`, "post", {
      id: item?.id,
      value,
      parent_label_user_id: userData?.user_id,
      parent_label_username: userData?.name,
      parent_label_timestamp: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
    })
      .then((res) => {
        setFeeds((prev) =>
          prev?.map((item_) =>
            item_?.id === item?.id
              ? {
                  ...item_,
                  parent_label: value ? 1 : 0,
                  parent_label_user_id: userData?.user_id,
                  parent_label_username: userData?.name,
                }
              : item_
          )
        );
      })
      .finally(() => {
        setLocalLoading(null);
      });
  };

  const shareFeedVid = (id) => {
    const host = window.location.host;
    const link = host + `/feed/${id}`;
    //COPY Logic
    const inp = document.createElement("textarea");
    document.body.appendChild(inp);
    inp.value = link;

    inp.select();
    document.execCommand("copy");
    inp.parentNode.removeChild(inp);

    toast.success("Link Copied to Clipboard", {
      position: "bottom-center",
      style: {
        border: "1px solid #713200",
        padding: "16px",
        color: "#713200",
      },
      iconTheme: {
        primary: "#713200",
        secondary: "#FFFAEE",
      },
    });
  };
  useEffect(() => {
    setFeeds(null);
    setFeedType(section?.subSections[0]?.key);
  }, [section]);

  useEffect(() => {
    setOffset(0);
  }, [filterChipVal, section, feedType]);

  const checkHeader = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (clientHeight + scrollTop >= scrollHeight - 150) {
      setOffset((prev) => prev + 10);
    }

    setHeaderBg(scrollTop > 10);
  };
  const onClientEntry = () => {
    window.addEventListener("scroll", checkHeader);
  };

  const mapFilterChipValues = (item) => {
    setFeeds(null);
    setFilterChipVal(item.value);
  };

  useEffect(() => {
    onClientEntry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    setLoading(true);

    fetchGet(
      BASE_URL +
        `/get-sm-feed?school_id=${school_id}&offset=${offset}&limit=${limit}&type=${feedType}&teachersFeedStr=${filterChipVal}`
    )
      .then((res) => {
        if (!feeds) {
          setFeeds(res?.data?.results);
        } else {
          if (feeds === null) return;
          setFeeds((prev) => {
            return prev?.concat(res?.data?.results);
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const observeVideos = () => {
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry?.target?.play();
        } else {
          entry?.target?.pause();
        }
      });
    };
    const options = { threshold: 0.5 };
    const observer = new IntersectionObserver(callback, options);
    const videos = document.querySelectorAll(".feed-video");
    if (videos?.length === 0) return;
    videos?.forEach((vid) => {
      observer.observe(vid);
    });
  };

  useEffect(() => {
    observeVideos();
  }, [feeds]);

  useEffect(() => {
    if (feeds && feeds?.length % limit !== 0) return;

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedType, offset, filterChipVal, section]);

  return (
    <>
      {feedId && (
        <Modal show={true} backdrop="static" keyboard={false}>
          <Modal.Body
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              maxHeight: "90vh",
            }}
          >
            <div className="d-flex">
              <i
                onClick={() => setFeedId(null)}
                class="bi pointer fs-20 bi-x ms-auto"
              ></i>
            </div>
            <Comments userData={userData} feedId={feedId} />
          </Modal.Body>{" "}
        </Modal>
      )}
      <div
        className={`position-fixed w-100 border-bottom top-0 bg-${
          headerBg ? "light" : ""
        } trans-3s`}
        style={{ zIndex: 999, backdropFilter: "blur(10px)" }}
      >
        <div className="container w-100 py-3 d-flex align-items-center justify-content-between">
          <div className="btn-group">
            {SECTIONS.map((item, index) => (
              <button
                key={item?.name}
                className={`btn border fw-500 fs-15 rounded shadow-none ${headerBtnsText(
                  headerBg,
                  section?.name,
                  item?.name
                )} ${index > 0 ? "ms-4" : ""} white d-flex align-items-center`}
                type="button"
                onClick={() => {
                  setFilterChipVal(FILTER_CHIPS[0].value);
                  setSection(item);
                }}
              >
                <p className="m-0 ff-inter">{item?.name}</p>{" "}
                <i className={`bi bi-chevron-down ms-2`}></i>
              </button>
            ))}
          </div>

          <div className="d-flex align-items-center justify-content-end">
            <p className="m-0 text-capitalize me-4 text-center ff-inter fw-400 text-secondary fs-16 d-flex align-items-center">
              {!isTabletOrMobile && "[Current Tab :"}
              <p className="m-0 text-wmg ms-1">
                {" "}
                {CURR_LABEL_INDICATOR[feedType]}
              </p>
              {!isTabletOrMobile && "]"}
            </p>
          </div>
        </div>
      </div>
      <div className="pt-3 text-white user-select-none">X</div>

      <div className="container mt-5">
        <div className="row py-2">
          <div className="col-12 col-md-3 border-none border-md-end pe-4 mb-4 mb-md-0">
            {section?.subSections?.map((item, index) => (
              <div
                onClick={() => {
                  if (feedType === item?.key) return;
                  setFeeds(null);
                  setFilterChipVal(FILTER_CHIPS[0].value);
                  setFeedType(item?.key);
                }}
                className={`${
                  index > 0 ? "mt-4" : ""
                } ff-inter fs-14 fw-500 border rounded d-flex justify-content-between pe-3 p-2 pointer ${highlightFeedType(
                  item,
                  feedType
                )}`}
                key={item?.label}
              >
                <p className="m-0">{item?.label}</p>
                {!isTabletOrMobile && (
                  <i className="bi bi-chevron-double-right"></i>
                )}
              </div>
            ))}
          </div>

          <div className="col-12 col-md-9">
            <div className="row flex-row g-1 g-md-3">
              {FILTER_CHIPS.map((item) => (
                <div key={item.name} className="col-auto">
                  <p
                    onClick={() =>
                      filterChipVal === item.value
                        ? {}
                        : mapFilterChipValues(item)
                    }
                    className={`pointer user-select-none ff-inter px-3 m-0 fs-14 py-1 shadow-sm filter-chip ${
                      filterChipVal === item.value ? "filter-chip-active" : ""
                    }`}
                  >
                    {item.name}
                  </p>
                </div>
              ))}
            </div>

            {feeds &&
              feeds?.map((item, index) => (
                <div className="row">
                  <div className="mt-4 col-12 col-md-7">
                    <div
                      key={item?.id + index.toString()}
                      className="position-relative border-bottom mb-4"
                    >
                      <div className="row w-100 m-0 py-1">
                        <div className="col-12 p-0">
                          <p className="ff-inter text-secondary fw-500 fs-15 m-0">
                            {item?.datetime_string}
                          </p>
                        </div>

                        <div className="col-12 p-0 mt-2">
                          <p className="mx-auto text-start text-wmg w-100 user-select-none ff-inter fs-15 fw-400 m-0">
                            Camera Name :{" "}
                            {CAMERAS[item?.camera_no]
                              ? CAMERAS[item?.camera_no]
                              : item?.camera_no}
                          </p>
                        </div>
                      </div>

                      <div
                        className="position-relative bg-dark"
                        style={{
                          height: height * 0.8,
                        }}
                      >
                        <div className="position-relative">
                          <VideoCard
                            height={height * (feedType === "feed" ? 0.67 : 0.7)}
                            item={item}
                          />

                          {item?.id === localLoading && (
                            <div
                              className="position-absolute w-100 h-100 top-0 left-0 d-flex justify-content-center align-items-center ff-inter fw-400 text-white ff-16"
                              style={{
                                background: "#00000025",
                                zIndex: 998,
                              }}
                            >
                              <CircularProgress />
                            </div>
                          )}

                          <div>
                            {feedType === "feed" && (
                              <div
                                className={`heartAnimation ${
                                  item.parent_label ? "active" : ""
                                }`}
                                onClick={(e) =>
                                  updateShowVideoToParent(
                                    e,
                                    item,
                                    !item.parent_label
                                  )
                                }
                              ></div>
                            )}
                          </div>
                        </div>
                        <div
                          className={`position-absolute w-100 m-0 left-0 bottom-0 row align-items-center pb-${
                            feedType === "feed" ? "2" : "0"
                          }`}
                          style={{
                            height: height * (feedType === "feed" ? 0.13 : 0.1),
                          }}
                        >
                          {feedType === "feed" && (
                            <div className="text-white text-center my-1 ff-inter fw-500 fs-16">
                              Coach Teachers
                            </div>
                          )}
                          <div className="col-6 d-flex align-items-center justify-content-center px-2">
                            <div className="px-2 w-100">
                              <button
                                onClick={() =>
                                  updateTeacherFeed(
                                    item?.school_label === 0 ? null : false,
                                    item
                                  )
                                }
                                type="button"
                                className={`btn ${
                                  REJECT_BTN[item?.school_label]
                                } w-100 shadow-none trans-3s py-1 border-none`}
                              >
                                <p
                                  className={`m-0 ff-inter fw-600 fs-15 text-white trans-3s`}
                                >
                                  {feedType === "feed"
                                    ? "Avoid this"
                                    : "Reject"}
                                </p>
                              </button>
                            </div>
                          </div>
                          <div className="col-6 d-flex border-start align-items-center justify-content-center">
                            <div className="px-2 w-100">
                              <button
                                type="button"
                                onClick={() =>
                                  updateTeacherFeed(
                                    item?.school_label === 1 ? null : true,
                                    item
                                  )
                                }
                                className={`btn ${
                                  ACCEPT_BTN[item?.school_label]
                                } w-100 py-1 shadow-none trans-3s border-none`}
                              >
                                <p
                                  className={`m-0 ff-inter fw-600 fs-15 text-white trans-3s`}
                                >
                                  {feedType === "feed" ? "Do this" : "Accept"}
                                </p>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between py-3">
                        <div className="d-flex align-items-center col-4">
                          <i
                            onClick={() => shareFeedVid(item?.id)}
                            className="bi me-4 pointer fs-28 fc-cprimary bi-link-45deg"
                          ></i>

                          <i
                            onClick={() => setFeedId(item?.id)}
                            className="bi me-4 pointer fs-24 fc-cprimary bi-chat-square-text"
                          ></i>
                        </div>

                        {item?.school_user_id && (
                          <div className="col-8 d-flex align-items-center">
                            <p className="w-100 text-end mx-auto text-secondary w-100 user-select-none ff-inter fs-14 fw-400 m-0">
                              Last Changed by{" "}
                              <span className="fw-600 text-wmg">
                                {item?.school_user_name}
                              </span>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {!isTabletOrMobile && (
                    <div
                      className="col-5 border-start pt-4 mt-4 rounded ms-auto custom-scroll-bar"
                      style={{
                        height: height * 0.85,
                        overflow: "auto",
                      }}
                    >
                      <Comments
                        role={role}
                        userData={userData}
                        feedId={item?.id}
                      />
                    </div>
                  )}
                </div>
              ))}

            {feeds?.length === 0 && (
              <p className="m-0 mt-4 text-secondary text-center">No Feed</p>
            )}
            {loading && (
              <div className="d-flex align-items-center py-5 justify-content-center">
                <img
                  width="75"
                  className="mx-auto"
                  alt="loader"
                  height="75"
                  src={Spinner}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareableMoments;
