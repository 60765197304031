import { useState, useEffect } from "react";
import Moment from "react-moment";
import Loader from "../../components/loader/loader";
import VideoCard from "../../components/video-card/video-card";
import { BASE_URL, FEED_TYPE, FILTERS } from "../../constants/appConstants";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { correctTime } from "../../utils/date";
import { fetchGet } from "../../utils/fetchGet";
//Deprecated
const SMFeeds = (props) => {
  const [feeds, setFeeds] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [feedType, setFeedType] = useState(FEED_TYPE?.feed);
  const [loading, setLoading] = useState(false);
  const [headerBg, setHeaderBg] = useState(false);

  const { height } = useWindowDimensions();

  const { school_id } = props?.match?.params;
  console.log(school_id);

  const checkHeader = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

    if (clientHeight + scrollTop >= scrollHeight - 5) {
      setOffset((prev) => prev + 10);
    }

    setHeaderBg(scrollTop > 10);
  };

  useEffect(() => {
    if (feeds && feeds?.length % limit !== 0) return;

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedType, offset]);

  const onClientEntry = () => {
    window.addEventListener("scroll", checkHeader);
  };
  const loadData = () => {
    setLoading(true);
    fetchGet(
      BASE_URL +
        `/get-sm-feed?school_id=${school_id}&offset=${offset}&limit=${limit}&type=${feedType}&teachersFeedStr=(1,0,-1)`
    )
      .then((res) => {
        if (!feeds) {
          setFeeds(res?.data?.results);
        } else {
          setFeeds((prev) => prev.concat(res?.data?.results));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    onClientEntry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const observeVideos = () => {
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry?.target?.play();
        } else {
          entry?.target?.pause();
        }
      });
    };
    const options = { threshold: 0.5 };
    const observer = new IntersectionObserver(callback, options);
    const videos = document.querySelectorAll(".feed-video");
    if (videos?.length === 0) return;
    videos?.forEach((vid) => {
      observer.observe(vid);
    });
  };

  useEffect(() => {
    observeVideos();
  }, [feeds]);

  return (
    <>
      <div
        className={`position-fixed w-100 top-0 bg-${headerBg ? "dark" : ""} ${
          !headerBg ? "border-bottom" : ""
        } py-3 trans-3s`}
        style={{ zIndex: 99 }}
      >
        <div className="container w-100">
          <div className="btn-group">
            <button
              className={`btn border rounded shadow-none text-${
                headerBg ? "white" : "dark"
              } white d-flex align-items-center`}
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <p className="m-0">Alerts</p>{" "}
              <i
                className={`bi bi-chevron-down text-${
                  headerBg ? "white" : "dark"
                } ms-2`}
              ></i>
            </button>
            <ul className="dropdown-menu">
              {FILTERS.map((item, index) => (
                <li
                  className="dropdown-item pointer"
                  data-bs-toggle="dropdown"
                  onClick={() => {
                    if (feedType === item?.action) return;

                    setFeeds(null);
                    setFeedType(item?.action);
                  }}
                  key={item?.action}
                >
                  {item?.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="container pt-5">
        <div className="row">
          <div className="mt-5 col-12 col-md-7 mx-auto">
            {feeds &&
              feeds?.map((item, index) => (
                <div
                  key={index}
                  className="position-relative mb-3 border-bottom pb-3"
                >
                  <div
                    style={{
                      height: height * 0.05,
                    }}
                    className="d-flex align-items-center"
                  >
                    <div className="m-0 ff-inter text-secondary fw-500 fs-15 m-0 mb-2 d-flex align-items-center">
                      <Moment fromNow>{correctTime(item?.addedOn)}</Moment>{" "}
                      <p className="mx-1"> </p>(
                      <Moment format="LLLL">
                        {correctTime(item?.addedOn)}
                      </Moment>
                      )
                    </div>
                  </div>

                  <VideoCard height={height * 0.75} item={item} />
                </div>
              ))}
            {feeds?.length === 0 && (
              <p className="m-0 text-secondary text-center">No Feed</p>
            )}
            {loading && (
              <div className="d-flex align-items-center justify-content-center">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SMFeeds;
