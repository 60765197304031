import React, { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";
import Loader from "../../components/loader/loader";
import { APIS, BASE_URL } from "../../constants/appConstants";
import { getHourMins } from "../../utils/date";
import { fetchGet } from "../../utils/fetchGet";
import moment from "moment";
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};
const COLOR = "#" + ((Math.random() * 0xffffff) << 0).toString(16);

const formateData = (userUsage) => {
  const labels = userUsage?.map(
    (item) =>
      `${moment(item?.date).format("MMMM Do YYYY")} [${getHourMins(
        item?.diff
      )}]`
  );

  return {
    labels,

    datasets: [
      {
        label: userUsage[0]?.name,
        data: userUsage?.map((item) => item?.diff),
        backgroundColor: COLOR,
      },
    ],
  };
};

export const WebviewUsage = (props) => {
  const [userUsage, setUserUsage] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user_id } = props?.match?.params;

  useEffect(() => {
    setLoading(true);
    fetchGet(BASE_URL + `${APIS.GET_USER_USAGE}?user_id=${user_id}`)
      .then((res) => {
        setUserUsage(res?.data?.results);
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container pt-5">
      <div className="row">
        <div>
          {userUsage && <Bar options={options} data={formateData(userUsage)} />}
        </div>
      </div>

      {loading && (
        <div className="d-flex align-items-center justify-content-center py-4">
          <Loader />
        </div>
      )}
    </div>
  );
};
