import { useContext, useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import { APIS, BASE_URL, FEED_TYPE } from "../../constants/appConstants";
import { getFormattedDate } from "../../utils/date";
import { fetchGet } from "../../utils/fetchGet";
import Spinner from "../../assets/svgs/spinner.svg";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
export const AlertReport = () => {
  const [dateFilter, setDateFilter] = useState({
    date: new Date(),
    formattedDate: getFormattedDate(new Date()),
  });
  const [report, setReport] = useState(null);
  const [reportMain, setReportMain] = useState(null);
  const [loading, setLoading] = useState(false);
  const { userDbData } = useContext(AppContext);
  const [allSchoolsData, setAllSchoolsData] = useState(null);
  const [query, setQuery] = useState("");
  const fetchReport = () => {
    setLoading(true);
    fetchGet(
      BASE_URL +
        APIS.ALERT_REPORTS_BY_DAY +
        `?dateFilter=${dateFilter?.formattedDate}`
    )
      .then((res) => {
        setReportMain(res?.data?.report);
        setAllSchoolsData(res?.data?.all_schools);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setReport(
        reportMain?.filter((item) => {
          return item?.school_name
            ?.toLowerCase()
            ?.trim()
            ?.includes(query?.toLowerCase()?.trim());
        })
      );
    }, 250);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, reportMain]);

  return (
    <div className="container">
      <div className="row m-0 mt-4">
        <p className="m-0 ff-intern p-0 fw-500 fs-20">
          Report of alerts available in Database per Day per School
        </p>

        <div className="row my-4 justify-content-between align-items-center">
          <div className="col-12 col-md-4 m-0 p-0">
            <Flatpickr
              options={{
                dateFormat: "J F Y",
              }}
              data-enable-time
              value={dateFilter?.date}
              onChange={(date) => {
                setDateFilter({
                  date,
                  formattedDate: getFormattedDate(date),
                });
              }}
              className="m-0 shadow-sm form-control ff-inter fw-500 fs-14"
              style={{
                color: "#000",
                fontSize: 15,
              }}
            />
          </div>
          <div className="col-12 mt-2 mt-md-0 col-md-6 p-0">
            <input
              className="form-control shadow-sm"
              placeholder="Search schools"
              value={query}
              type="search"
              onChange={(e) => setQuery(e?.target?.value)}
            />
          </div>
        </div>
      </div>
      {loading && (
        <div className="d-flex pt-5 align-items-center justify-content-center">
          <img width="55" color="red" alt="loader" height="55" src={Spinner} />
        </div>
      )}
      {!loading && report?.length > 0 && (
        <div className="mt-4">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">School Name</th>
                <th scope="col">Person Detected</th>
                <th scope="col">Child Left Alone</th>
                <th scope="col">Moments</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>0</th>
                <td>
                  <Link
                    target={"_blank"}
                    to={`/all/${userDbData?.user_id}?date=${new Date(
                      dateFilter?.date
                    )?.getTime()}`}
                  >
                    <p className="m-0 fw-600 text-success fs-18">All Schools</p>
                  </Link>
                </td>
                <td>
                  <Link
                    target={"_blank"}
                    to={`/all/${userDbData?.user_id}?date=${new Date(
                      dateFilter?.date
                    )?.getTime()}&type=${FEED_TYPE.intruderAlert}`}
                  >
                    <p className="m-0 fw-600 text-success fs-18">
                      {allSchoolsData?.intruder_alert_count}
                    </p>
                  </Link>
                </td>
                <td>
                  <Link
                    target={"_blank"}
                    to={`/all/${userDbData?.user_id}?date=${new Date(
                      dateFilter?.date
                    )?.getTime()}&type=${FEED_TYPE.childLeftBehind}`}
                  >
                    <p className="m-0 fw-600 text-success fs-18">
                      {allSchoolsData?.child_left_behind_alert_count}
                    </p>
                  </Link>
                </td>
                <td>
                  <Link
                    target={"_blank"}
                    to={`/all/${userDbData?.user_id}?date=${new Date(
                      dateFilter?.date
                    )?.getTime()}&type=${FEED_TYPE.feed}`}
                  >
                    <p className="m-0 fw-600 text-success fs-18">
                      {allSchoolsData?.feed_count}
                    </p>
                  </Link>
                </td>

                <td>
                  <Link
                    target={"_blank"}
                    to={`/all/${userDbData?.user_id}?date=${new Date(
                      dateFilter?.date
                    )?.getTime()}&type=All`}
                  >
                    <p className="m-0 fw-600 text-success fs-18">
                      {allSchoolsData?.total_count}
                    </p>
                  </Link>
                </td>
              </tr>

              {report?.map((item, index) => {
                return (
                  <tr key={item?.school_id}>
                    <th>{index + 1}</th>
                    <th scope="row">
                      <Link
                        target={"_blank"}
                        to={`/${item?.school_id}/${
                          userDbData?.user_id
                        }?date=${new Date(dateFilter?.date)?.getTime()}`}
                      >
                        <p className="m-0 ff-inter fw-400 fs-16">
                          {item?.school_name || item?.school_id}
                        </p>
                      </Link>
                    </th>
                    <td>
                      <Link
                        target={"_blank"}
                        to={`/${item?.school_id}/${
                          userDbData?.user_id
                        }?date=${new Date(dateFilter?.date)?.getTime()}&type=${
                          FEED_TYPE.intruderAlert
                        }`}
                      >
                        <p className="m-0 ff-inter fw-400 fs-18">
                          {item?.intruder_alert_count}
                        </p>
                      </Link>
                    </td>
                    <td>
                      <Link
                        target={"_blank"}
                        to={`/${item?.school_id}/${
                          userDbData?.user_id
                        }?date=${new Date(dateFilter?.date)?.getTime()}&type=${
                          FEED_TYPE.childLeftBehind
                        }`}
                      >
                        <p className="m-0 ff-inter fw-400 fs-18">
                          {item?.child_left_behind_alert_count}
                        </p>
                      </Link>
                    </td>
                    <td>
                      <Link
                        target={"_blank"}
                        to={`/${item?.school_id}/${
                          userDbData?.user_id
                        }?date=${new Date(dateFilter?.date)?.getTime()}&type=${
                          FEED_TYPE.feed
                        }`}
                      >
                        <p className="m-0 ff-inter fw-400 fs-18">
                          {item?.feed_count}
                        </p>
                      </Link>
                    </td>
                    <td>
                      <Link
                        target={"_blank"}
                        to={`/${item?.school_id}/${
                          userDbData?.user_id
                        }?date=${new Date(
                          dateFilter?.date
                        )?.getTime()}&type=All`}
                      >
                        <p className="m-0 ff-inter fw-400 fs-18">
                          {item?.total_count}
                        </p>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {!loading && report?.length === 0 && (
        <div className="pt-5 d-flex justify-content-center">
          <p className="m-0 ff-inter fw-500 fs-20">No School Found</p>
        </div>
      )}
    </div>
  );
};
