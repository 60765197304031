import CryptoJS from "crypto-js";

export const getQueryParameters = (query) => {
  const obj = {};
  const queryArr = query?.replace("?", "")?.split("&");

  queryArr?.forEach((item) => {
    const splitted = item?.split("=");
    obj[splitted[0]] = splitted[1];
  });

  return obj;
};

export const encryptToken = (token) => {
  const secretKey = process.env.REACT_APP_DECRYPTION_SECRET_KEY;
  // Encrypt the token using AES encryption with the provided secret key
  const encryptedToken = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(token),
    secretKey
  ).toString();
  return encryptedToken;
};

export const decryptToken = (encryptedToken) => {
  const secretKey = process.env.REACT_APP_DECRYPTION_SECRET_KEY;
  // Decrypt the encrypted token using AES decryption with the provided secret key
  const decryptedTokenBytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const decryptedToken = decryptedTokenBytes.toString(CryptoJS.enc.Utf8);
  return decryptedToken;
};
