import Avatar from "@mui/material/Avatar";
import { deepPurple } from "@mui/material/colors";
// import Moment from "react-moment";

const Comment = ({ comment, index }) => {
  return (
    <div key={index} className="d-flex mb-3">
      <div className="d-flex flex-column">
        <Avatar sx={{ bgcolor: deepPurple[500] }}></Avatar>
      </div>
      <div className="ms-3">
        <div className="d-flex">
          <p className="ff-inter m-0 text-primary fs-14 me-2">{comment.name}</p>
          {/* <Moment className="fs-14 text-primary" fromNow>
            {comment?.timestamp}
          </Moment>{" "} */}
        </div>

        <p className="m-0 fs-16">{comment?.commentText}</p>
      </div>
    </div>
  );
};

export default Comment;
