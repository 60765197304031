import { useCallback } from "react";
import { useEffect, useRef, useState } from "react";
import Loader from "../../components/loader/loader";
import { APIS, BASE_URL } from "../../constants/appConstants";
import { fetchGet } from "../../utils/fetchGet";
import Moment from "react-moment";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { correctTime, correctTime2 } from "../../utils/date";

export const AllCommentsListing = () => {
  const [comments, setComments] = useState(null);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [commentsByList, setCommentByList] = useState([]);
  const [userName, setUserName] = useState("");
  const { setDoucmentTitle } = useDocumentTitle("User Feedback");

  const limit = 10;

  useEffect(() => {
    fetchGet(BASE_URL + APIS.GET_ALL_COMMENTS_BY_LIST).then((res) => {
      setCommentByList(res?.data?.results);
    });
  }, []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, userName]);

  const loadData = () => {
    if (comments && comments?.length % limit !== 0) return;

    setLoading(true);

    fetchGet(
      BASE_URL +
        APIS.GET_ALL_COMMENTS +
        `?offset=${offset}&limit=${limit}&userName=${userName}`
    )
      .then((res) => {
        if (!comments) {
          setComments(res?.data?.results);
        } else {
          if (comments === null) return;
          setComments((prev) => prev.concat(res?.data?.results));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const observer = useRef();
  const commentRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setOffset((prev) => prev + limit);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  const onChange = (e) => {
    const val = e.target.value;
    setUserName(val);
    setComments(null);
    setOffset(0);
    setDoucmentTitle(`Feedback by ${val ? val : "All Users"}`);
  };

  return (
    <div className="container p-0">
      <div className="d-flex p-2 justify-content-between">
        <div className="col-4">
          <p className="m-0 text-primary fs-20 fw-500">User Feedbacks</p>
        </div>

        <div className="col-8">
          {commentsByList && (
            <div className="d-flex align-items-center justify-content-end">
              <p className="m-0 me-2 fs-16 text-wmg fw-500">Commented By : </p>
              <select
                onChange={onChange}
                className="form-control shadow-none w-75 m-0"
              >
                <option value="" selected={userName === ""}>
                  All Comments
                </option>
                {commentsByList?.map((item) => (
                  <option selected={item?.name === userName}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>
      <div className="row p-2">
        <div>
          {comments?.map((item) => (
            <div
              onClick={() => {
                window.open(
                  `https://app.class-proxima.com/feed/${item?.feedId}`,
                  "_blank"
                );
              }}
              key={item?.commentId}
              ref={commentRef}
              className="border rounded my-3 p-2 pointer shadow-sm user-select-none"
            >
              <p className="m-0 fw-600 fs-16">{item?.commentText}</p>

              <div className="d-flex align-items-center mt-1">
                <p className="m-0 text-primary fw-400 fs-14">{item?.name}</p>
                <p className="m-0 text-secondary fs-14 ms-2 fw-400">
                  <Moment fromNow>{correctTime2(item?.timestamp)}</Moment>
                </p>

                <p className="m-0 text-secondary fs-14 ms-2 fw-400">
                  [<Moment format="LLLL">{correctTime(item?.timestamp)}</Moment>
                  ]
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {loading && (
        <div className="d-flex align-items-center justify-content-center">
          <Loader />
        </div>
      )}
    </div>
  );
};
