// export const BASE_URL = "http://localhost:5000";
// export const BASE_URL = "http://15.236.104.176:5000/";
export const BASE_URL = window.location.origin;
// export const BASE_URL = "192.168.43.30:5000";

export const APIS = {
  GET_FEED: "/get-feed",
  UPDATE_PARENT_FEED: "/update-parent-feed",
  UPDATE_TEACHERS_FEED: "/update-teachers-feed",
  APPROVE_FEED: "/approve-feed",
  INITIAL_API: "/initial-api",
  INITIAL_API_BACKOFFICE: "/initial-api-backoffice",
  ADD_COMMENT: "/add-comment",
  ALL_COMMENTS: "/all-comments",
  GET_FEED_BY_ID: "/get-feed-by-id",
  GET_SUPERADMIN_CONFIG: "/get-superadmin-config",
  LOG_SESSION_START: "/log-session-start",
  LOG_SESSION_END: "/log-session-end",
  GET_USER_USAGE: "/get-user-usage",
  GET_ALL_USER_USAGE: "/get-all-user-usage",
  GET_ALL_COMMENTS: "/get-all-comments",
  GET_ALL_COMMENTS_BY_LIST: "/get-all-comments-by-list",
  UPDATE_SHOW_FEED_TO_PARENT: "/update-show-feed-to-parent",
  UPDATE_ADMIN_SHOW_FEED_TO_PARENT: "/update-admin-show-feed-to-parent",
  DISLIKE_FEED: "/dislike-feed",
  GET_FEED_STATS: "/get-feed-stats",
  CHECK_BACKOFFICE_USER: "/check-backoffice-user",
  GET_BACKOFFICE_USER: "/get-backoffice-user",
  ALERT_REPORTS_BY_DAY: "/alert-reports-by-day",
  GET_SCHOOL_LIST: "/get-school-list",
  ADD_LOG: "/add-log",
  GET_LOGS: "/get-all-logs",
  GET_SM_FEED: "/get-sm-feed",
};

export const FEED_TYPE = {
  feed: "feed",
  intruderAlert: "intruder_alert",
  childLeftBehind: "child_left_behind_alert",
};

export const FEED_TYPE_LABELS = {
  feed: "Feed",
  intruder_alert: "Person Detected",
  child_left_behind_alert: "Child Left Alone",
};

export const FILTERS = [
  { label: "All", action: FEED_TYPE?.feed, href: "all" },
  {
    label: "Person Detected",
    action: FEED_TYPE?.intruderAlert,
    href: "person_detected",
  },
  {
    label: "Child Left Alone",
    action: FEED_TYPE?.childLeftBehind,
    href: "child_left_alone",
  },
];

export const SECTION_TYPES = {
  Moments: "Moments",
  Alerts: "Alerts",
};

export const CURR_LABEL_INDICATOR = {
  feed: "Shareable Moments",
  intruder_alert: "Person Detected",
  child_left_behind_alert: "Child Left alone",
  All: "All Alerts",
};

export const CURR_LABEL_INDICATOR2 = {
  feed: "Shareable Moments",
  intruder_alert: "person detected",
  child_left_behind_alert: "child left alone",
};

export const SECTIONS = [
  {
    name: "Moments",
    subSections: [{ label: "Shareable Moments", key: "feed" }],
  },

  {
    name: "Alerts",
    subSections: [
      { label: "Person Detected", key: "intruder_alert" },
      { label: "Child Left alone", key: "child_left_behind_alert" },
    ],
  },
];

export const LOG_TYPES = {
  try_it_btn_clicked: "Try it Free for 30 Days Button Clicked",
  begin_trial_btn_clicked: "Begin Trial Button Clicked",
  trial_started_successfully: "Trial Started Successfully",
  trial_error: "Trial Error",
  wmg_alerts_page_viewed: "WMG Alerts Page Viewed",
};
