import { useEffect, useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
const PAGES = [
  { key: "SUPER_ADMIN_VIEW", title: "Super Admin View" },
  { key: "WMG_ALERTS_TAB", title: "WMG Alerts Tab" },
  { key: "SHAREABLE_MOMENTS", title: "Shareable Moments" },
];

const CARD_HEIGHT = 70;

export const CPServices = ({ userDbData }) => {
  const { schoolsList: dbSchoolsList } = useContext(AppContext);
  const [currPage, setCurrPage] = useState(PAGES[0]);
  const [searchTerm, setSearchTerm] = useState("");
  const [schoolsList, setSchoolList] = useState(dbSchoolsList);
  const SERVICES = [
    {
      label: "Comments",
      link: "/comments",
      internal: true,
    },
    {
      label: "Webview Usage",
      link: "/webview-usage",
      internal: true,
    },
    {
      label: "Dashboard",
      link: "/dashboard",
      internal: true,
    },
    {
      label: "Labelling Tool",
      link: "http://13.38.181.33:8080/",
      internal: false,
    },
    {
      label: "Alerts Report",
      link: "/report",
      internal: true,
    },
    { label: "Activity Logs Report", link: "/activity-logs", internal: true },
  ];
  useEffect(() => {
    setSchoolList(dbSchoolsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbSchoolsList]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSchoolList(
        dbSchoolsList?.filter((item) => {
          return item?.school_name
            ?.toLowerCase()
            ?.trim()
            ?.includes(searchTerm?.toLowerCase()?.trim());
        })
      );
    }, 250);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  if (!userDbData) {
    return null;
  }
  return (
    <div className="w-100 pb-5">
      <div className="row d-flex align-items-center flex-wrap g-3 mt-5 pt-2 border-top">
        <p className="m-0 text-dark fw-500 fs-18">Services</p>

        {SERVICES?.map((service) => (
          <div key={service?.label} className="col-12 col-md-3 pe-4">
            {service?.internal ? (
              <Link
                to={service.link}
                className="btn btn-primary w-100 ff-inter fs-15 fw-600"
              >
                {service?.label}
              </Link>
            ) : (
              <a
                rel="noreferrer"
                target="_blank"
                href={service?.link}
                className="btn btn-primary w-100 ff-inter fs-15 fw-600"
              >
                {service?.label}
              </a>
            )}
          </div>
        ))}
      </div>

      <div className="row d-flex align-items-center flex-wrap g-3 mt-5 pt-2 border-top">
        <p className="m-0 text-dark fw-500 fs-18">Tabs</p>
        <div className="d-flex align-items-center flex-wrap">
          {PAGES?.map((page) => (
            <button
              key={page?.key}
              onClick={() => setCurrPage(page)}
              type="button"
              className={`btn btn-${
                currPage?.key === page?.key ? "" : "outline-"
              }success ff-inter fs-15 fw-600 rounded-pill me-3 px-4 py-1 mb-3`}
            >
              {page?.title}
            </button>
          ))}
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between mt-3">
        <p className="m-0 text-dark fw-500 fs-18">
          {currPage.title} ({schoolsList?.length} Schools)
        </p>
        <div className="mt-2 mt-md-0 pe-3">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="bi bi-search"></i>
              </div>
            </div>
            <input
              type="search"
              className="form-control shadow-none"
              placeholder="Search by School Name"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      {schoolsList?.length === 0 && (
        <div className="d-flex align-items-center justify-content-center mt-5">
          <p className="m-0 text-dark fw-500 fs-18">No Schools</p>
        </div>
      )}
      {currPage.key === "SUPER_ADMIN_VIEW" && (
        <div className="row d-flex align-items-center flex-wrap g-3 mt-3">
          {schoolsList?.map((item) => (
            <div
              key={`superadmin-${item?.school_id}`}
              className="col-12 col-md-3 pe-4"
              style={{
                height: CARD_HEIGHT,
              }}
            >
              <Link
                to={`/${item?.school_id}/${userDbData.user_id}`}
                className="w-100 btn btn-outline-secondary border rounded d-flex align-items-center justify-content-center h-100"
              >
                <p className="m-0 ff-inter fs-15 fw-600 text-center">
                  {item?.school_name}
                </p>
              </Link>
            </div>
          ))}
        </div>
      )}
      {currPage.key === "WMG_ALERTS_TAB" && (
        <div className="row d-flex align-items-center flex-wrap g-3 mt-3">
          {schoolsList?.map((item) => (
            <div
              key={`alerts-${item?.school_id}`}
              className="d-flex col-12 col-md-3 pe-4"
              style={{
                height: CARD_HEIGHT,
              }}
            >
              <a
                rel="noreferrer"
                target="_blank"
                href={`https://app.class-proxima.com/${item?.school_id}/${userDbData.user_id}?mode=debug&view=direct`}
                className="w-100 btn btn-outline-secondary border rounded d-flex align-items-center justify-content-center h-100"
              >
                <p className="m-0 ff-inter fs-15 fw-600 text-center">
                  {item?.school_name}
                </p>
              </a>
            </div>
          ))}
        </div>
      )}

      {currPage.key === "SHAREABLE_MOMENTS" && (
        <div className="row d-flex align-items-center flex-wrap g-3 mt-3">
          {schoolsList?.map((item) => (
            <div
              key={`sm-${item?.school_id}`}
              className="col-12 col-md-3 pe-4"
              style={{
                height: CARD_HEIGHT,
              }}
            >
              <a
                rel="noreferrer"
                target="_blank"
                href={`/${item?.school_id}/${userDbData.user_id}/moments`}
                className="w-100 btn btn-outline-secondary border rounded d-flex align-items-center justify-content-center h-100"
              >
                <p className="m-0 ff-inter fs-15 fw-600 text-center">
                  {item?.school_name}
                </p>
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
