import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAHwI7XnJJiYOxjFUWGWom_CkUOEYKqHgU",
  authDomain: "class-proxima-e9dd5.firebaseapp.com",
  projectId: "class-proxima-e9dd5",
  storageBucket: "class-proxima-e9dd5.appspot.com",
  messagingSenderId: "763642869266",
  appId: "1:763642869266:web:7ff3a2ce3ff08678808acb",
  measurementId: "G-E1903T62J1"
};

firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({ timestampsInSnapshots: true });

export const auth = firebase.auth();

export { firebase };
