import React, { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { v4 as uuidv4 } from "uuid";
import Spinner from "../../assets/svgs/spinner.svg";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

export const MEDICAL_GPT_LAMBDA =
  "https://izclbphw7c.execute-api.us-west-2.amazonaws.com/default/medicalGPT-handler?api_key=ea420nhigg234fv32gkdhk";

export const SUGGESTIONS = [
  "Give me a summary of the studies on Congenital Adrenal Hyperplasia.",
  "What is the age group being studied in the above mentioned study?",
  "Can you build a table about this 1 study, including as columns: NCTId and Name of organisation sponsoring the study ?",
];

export const REPLY_SUGGESTIONS = [
  "Can you show me only the studies after 2012?",
  "Please narrow down the list to only studies where phase 2 is done",
  "Can you show me only the studies after 2012?",
  "Please narrow down the list to only studies where phase 2 is done",
];

export function DocChatBot() {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [session_id] = useState(uuidv4());
  const { height } = useWindowDimensions();
  useDocumentTitle("Chatbot AI");
  let controller = new AbortController();

  const scrollContainerRef = useRef(null);
  let scrollStartX = 0;

  const handleMouseDown = (event) => {
    scrollStartX = event.clientX || event.touches[0].clientX;
    scrollContainerRef.current.classList.add("grabbing");
  };

  const handleMouseMove = (event) => {
    if (scrollStartX !== 0) {
      const currentX = event.clientX || event.touches[0].clientX;
      const scrollX = currentX - scrollStartX;
      scrollContainerRef.current.scrollLeft -= scrollX;
      scrollStartX = currentX;
    }
  };

  const handleMouseUp = () => {
    scrollStartX = 0;
    scrollContainerRef.current.classList.remove("grabbing");
  };

  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };

  const handleSendMessage = (event) => {
    event.preventDefault();

    if (!userInput || loading) return;

    setMessages((prev) => [...prev, { message: userInput, sender: "user" }]);

    setLoading(true);
    var requestOptions = {
      method: "POST",
      // redirect: "follow",
      signal: controller.signal,
    };
    console.log("session_id", session_id);
    fetch(
      `https://izclbphw7c.execute-api.us-west-2.amazonaws.com/default/medicalGPT-handler?api_key=ea420nhigg234fv32gkdhk&session_id=${session_id}&query="${userInput}"`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setMessages((prev) => [
          ...prev,
          { message: result?.response, sender: "bot" },
        ]);
        setUserInput("");
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch request was aborted");
        } else {
          console.error("Fetch request failed", error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const stopGeneratingReponse = () => {
    console.log("sd");
    controller.abort();
    console.log("s2d");
  };
  const scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  return (
    <div
      style={{
        minHeight: height * 0.999,
        paddingBottom: 122,
        background: "#F4F6FC",
      }}
    >
      <div className="pb-5">
        <div className="row m-0 p-0">
          {messages.map((message, index) => (
            <div
              style={{
                background: message.sender === "bot" ? "#ffffff" : "",
                borderRadius: 12,
              }}
              className={`col-12 px-3 col-md-8 mx-auto d-flex flex-column py-3 user-select-none align-items-start ${
                message.sender === "bot" ? "shadow-sm border" : ""
              }`}
            >
              <div className="d-flex">
                {message.sender !== "bot" ? (
                  <i class="bi bi-person-circle m-0 text-primary fs-24"></i>
                ) : (
                  <i class="bi bi-arrow-return-right m-0 text-primary fs-20"></i>
                )}
                <p
                  style={{
                    lineHeight: 2,
                    color: "#1F1F1F",
                  }}
                  key={index}
                  className={`m-0 ff-inst pt-1 ms-2 px-2 table`}
                >
                  <table
                    dangerouslySetInnerHTML={{
                      __html: message?.message,
                    }}
                  ></table>
                </p>
              </div>

              {message.sender === "bot" && index === messages?.length - 1 && (
                <div className="suggestions-container mt-3 mb-2">
                  <div
                    className="suggestions-scroll"
                    ref={scrollContainerRef}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleMouseDown}
                    onTouchMove={handleMouseMove}
                    onTouchEnd={handleMouseUp}
                  >
                    {REPLY_SUGGESTIONS.map((suggestion, index) => (
                      <div
                        onClick={() => {
                          setUserInput(suggestion);
                        }}
                        className="suggestion px-3 py-2 me-2 ff-inst fs-16 fw-500"
                        key={index}
                      >
                        {suggestion}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}

          {/* loader  */}
          {loading && (
            <div
              style={{
                borderRadius: 12,
              }}
              className="col-12 bg-white user-select-none border shadow-sm col-md-8 mx-auto d-flex align-items-start py-3"
            >
              <img
                width="35"
                color="red"
                alt="loader"
                height="35"
                src={Spinner}
              />
            </div>
          )}
        </div>
        <div
          style={{
            background: "#F4F6FC",
          }}
          className="position-fixed m-0 start-0 bottom-0 w-100 py-4 d-flex justify-content-center"
        >
          {/* {loading && (
            <div className="position-absolute d-flex align-items-center justify-content-center mb-5 bottom-50">
              <button
                className="btn btn-outline-danger d-flex align-items-center"
                onClick={stopGeneratingReponse}
              >
                <i class="bi bi-stop-circle me-2"></i>
                <p className="ff-inst fs-14 m-0">Stop generating</p>
              </button>
            </div>
          )} */}

          <form
            className="col-12 col-md-8 mx-auto m-0 p-0 d-flex align-items-end px-2"
            onSubmit={handleSendMessage}
          >
            <textarea
              disabled={loading}
              className="form-control ff-inst text-dark shadow-sm me-2"
              type="text"
              value={userInput}
              onChange={handleUserInput}
              placeholder="Enter a prompt here"
            ></textarea>
            <button className="btn btn-white" type="submit">
              <i class="bi bi-send fs-20 text-primary"></i>
            </button>
          </form>
        </div>
      </div>

      {/* empty screen suggestions */}
      {messages?.length === 0 && (
        <div className="container">
          <div className="row">
            <div className="col-10 mx-auto">
              <i class="bi bi-stars fs-32"></i>
              <p className="text-primary ff-inst fs-24 fw-600">
                Chatbot still in its experimental phase. Interacting with it and
                evaluating its responses while it's in the experimental phase
                can contribute to enhancing the overall experience.{" "}
              </p>
              <p className="text-primary ff-inst fs-18 fw-600">
                Example prompts to get started:
              </p>

              <div className="d-flex flex-column align-items-start">
                {SUGGESTIONS?.map((sugg) => (
                  <div
                    style={{
                      borderRadius: 99,
                    }}
                    className="btn btn-outline-secondary mb-4"
                    onClick={() => {
                      setUserInput(sugg);
                    }}
                  >
                    <p className="m-0 ff-inst fw-400 fs-16">{sugg}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
