export const DeleteAccount = () => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const email = data.get("email");

    e?.target?.reset();
  };
  return (
    <div>
      <header className="bg-light border-bottom py-2">
        <div className="container">
          <p className="ff-inter m-0 fs-18 fw-600 py-2">Voice GPT</p>
        </div>
      </header>

      <div className="container">
        <div className="row">
          <form onSubmit={handleSubmit} className="col-5 mx-auto mt-5">
            <label>Email</label>
            <input
              name="email"
              type="email"
              className="form-control my-3 shadow-sm"
              required
            />
            <button className="btn btn-outline-primary">Delete Account</button>
          </form>
        </div>
      </div>
    </div>
  );
};
