import "./video-card.css";

const VideoCard = ({ videoRef, item, height, isControls }) => {
  return (
    <div
      style={{
        height,
      }}
      className="feed-video-wrapper"
    >
      <video
        ref={videoRef}
        autoPlay
        muted="muted"
        controls={isControls}
        controlsList="nodownload"
        className="feed-video"
        playsInline
        loop
      >
        <source src={item?.post} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoCard;
