import moment from "moment";
import { useState, useEffect, memo, useCallback } from "react";
import Loader from "../../components/loader/loader";
import VideoCard from "../../components/video-card/video-card";
import {
  APIS,
  BASE_URL,
  CURR_LABEL_INDICATOR,
  FEED_TYPE,
  FEED_TYPE_LABELS,
  FILTERS,
} from "../../constants/appConstants";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { fetchGet } from "../../utils/fetchGet";
import { fetchPost } from "../../utils/fetchPost";
import { Modal } from "react-bootstrap";
import Comments from "../../components/comments/comments";
import toast from "react-hot-toast";
import { useRef } from "react";
import "moment-timezone";
import { useMediaQuery } from "react-responsive";
import CircularProgress from "@mui/material/CircularProgress";
import Flatpickr from "react-flatpickr";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import axios from "axios";
import { SuperAdminStats } from "../../components/super-admin-stats/super-admin-stats";
import { getFormattedDate } from "../../utils/date";
import { useLocation } from "react-router-dom";
import { getQueryParameters } from "../../utils/appUtils";

const FILTER_CHIPS = [
  { name: "All", value: "(1,-1,0)" },
  { name: "Do this", value: "(1)" },
  { name: "Avoid this", value: "(0)" },
  { name: "Not Decided", value: "(-1)" },
];

const FILTER_CHIPS_NEW = [
  {
    name: "Liked ",
    key: "likeFilterStr",
    clickValue: "AND COALESCE(admin_parent_label, -1) IN (1)",
    unclickValue: "",
  },
  {
    name: "Rejected",
    key: "dislikeFilterStr",
    clickValue: "AND COALESCE(dislike_label, -1) IN (1)",
    unclickValue: "",
  },
];

const ACCEPT_BTN = {
  null: "bg-secondary",
  0: "bg-secondary",
  1: "bg-primary",
};

const REJECT_BTN = {
  null: "bg-secondary",
  1: "bg-secondary",
  0: "bg-danger",
};
export const totalVids = (stats = []) =>
  stats?.reduce((prev, item) => item?.count + prev, 0);

export const totalApproved = (stats) =>
  stats?.filter((item) => item?.superadmin_label === 1)[0]?.count || 0;

export const totalRejected = (stats) =>
  stats?.filter((item) => item?.superadmin_label === 0)[0]?.count || 0;

const DEF_VAL_PAGINATION = (date, type) => ({
  feedType: type ? type : FEED_TYPE?.childLeftBehind,
  offset: 0,
  limit: 10,
  filterChipVal: FILTER_CHIPS[0].value,
  dateFilter: date,
  formattedDate: getFormattedDate(date),
  likeFilterStr: "",
  dislikeFilterStr: "",
});

const SuperAdminFeeds = (props) => {
  const { search } = useLocation();
  const { date, type } = getQueryParameters(search);
  const [feeds, setFeeds] = useState(null);
  const [limit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [headerBg, setHeaderBg] = useState(false);
  const [feedId, setFeedId] = useState(null);

  const { height } = useWindowDimensions();
  const { role, userData, school_id } = props;
  const debugMode = role === "SUPER_ADMIN";
  const [approved, setApproved] = useState(-1);
  const [stats, setStats] = useState(null);
  const [schoolDetails, setSchoolDetails] = useState(null);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const [localLoading, setLocalLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState(
    DEF_VAL_PAGINATION(date ? new Date(parseInt(date)) : new Date(), type)
  );
  const [allStats, setAllStats] = useState(null);

  const checkHeader = () => {
    const { scrollTop } = document.documentElement;

    setHeaderBg(scrollTop > 10);
  };

  const updatePaginationValues = (newValues) => {
    setPaginationConfig((prev) => ({
      ...prev,
      ...newValues,
    }));
  };

  useEffect(() => {
    loadData();
    loadStatsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig]);

  const onClientEntry = () => {
    window.addEventListener("scroll", checkHeader);
  };
  const loadData = () => {
    if (feeds && feeds?.length % limit !== 0) return;

    setLoading(true);
    fetchGet(
      BASE_URL +
        `${APIS.GET_SM_FEED}?school_id=${school_id}&offset=${paginationConfig?.offset}&limit=${paginationConfig?.limit}&type=${paginationConfig?.feedType}&teachersFeedStr=${paginationConfig?.filterChipVal}&dateFilter=${paginationConfig?.formattedDate}&likeFilterStr=${paginationConfig?.likeFilterStr}&dislikeFilterStr=${paginationConfig?.dislikeFilterStr}`
    )
      .then((res) => {
        setSchoolDetails(res?.data?.school_details);
        setStats(res?.data?.stats);
        if (!feeds) {
          setFeeds(res?.data?.results);
        } else {
          if (feeds === null) return;
          setFeeds((prev) => prev.concat(res?.data?.results));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadStatsData = () => {
    fetchGet(
      BASE_URL +
        `${APIS.GET_FEED_STATS}?school_id=${school_id}&user_id=${userData?.user_id}&dateFilter=${paginationConfig?.formattedDate}`
    ).then((res) => {
      setAllStats(res?.data);
    });
  };
  useEffect(() => {
    onClientEntry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapFilterChipValues = (item) => {
    setFeeds(null);

    updatePaginationValues({
      offset: 0,
      filterChipVal: item?.value,
    });
  };

  const mapFilterNewChipValues = (key, value) => {
    setFeeds(null);

    updatePaginationValues({
      offset: 0,
      [key]: value,
    });
  };

  const shareFeedVid = (id) => {
    const host = window.location.host;
    const link = host + `/feed/${id}`;
    //COPY Logic
    const inp = document.createElement("textarea");
    document.body.appendChild(inp);
    inp.value = link;

    inp.select();
    document.execCommand("copy");
    inp.parentNode.removeChild(inp);

    toast.success("Link Copied to Clipboard", {
      position: "bottom-center",
      style: {
        border: "1px solid #713200",
        padding: "16px",
        color: "#713200",
      },
      iconTheme: {
        primary: "#713200",
        secondary: "#FFFAEE",
      },
    });
  };

  const downloadVid = (item) => {
    axios({
      url: item?.post,
      method: "GET",
      responseType: "video/mp4",
      headers: {
        "Content-type": "application/video",
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${item?.id}.mp4`);
      document.body.appendChild(link);
      link.click();
    });
  };

  const copyUUID = (id) => {
    //COPY Logic
    const inp = document.createElement("textarea");
    document.body.appendChild(inp);
    inp.value = id;

    inp.select();
    document.execCommand("copy");
    inp.parentNode.removeChild(inp);

    toast.success("Feed ID Copied to Clipboard", {
      position: "bottom-center",
      style: {
        border: "1px solid #713200",
        padding: "16px",
        color: "#713200",
      },
      iconTheme: {
        primary: "#713200",
        secondary: "#FFFAEE",
      },
    });
  };

  const observeVideos = () => {
    const callback = (entries) => {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting) {
          entry?.target?.play();
        } else {
          entry?.target?.pause();
        }
      });
    };
    const options = { threshold: 0.5 };
    const observer = new IntersectionObserver(callback, options);
    const videos = document.querySelectorAll(".feed-video");

    if (videos?.length === 0) return;
    videos?.forEach((vid) => {
      observer.observe(vid);
    });
  };

  useEffect(() => {
    observeVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeds]);

  const observer = useRef();
  const videoRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPaginationConfig((prev) => ({
          ...prev,
          offset: prev?.offset + prev?.limit,
        }));
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  const approveFeedVid = (item) => {
    fetchPost(BASE_URL + `${APIS.APPROVE_FEED}`, "post", {
      id: item?.id,
      timestamp: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
      cloudfront_url: item?.post,
      dateFilter: paginationConfig?.formattedDate,
    }).then((res) => {
      setApproved(item?.id);
    });
  };

  const updateTeacherFeed = (value, item) => {
    setLocalLoading(item?.id);
    fetchPost(BASE_URL + `${APIS.UPDATE_TEACHERS_FEED}`, "post", {
      id: item?.id,
      value,
      school_id,
      admin_user_name: userData?.name,
      admin_user_id: userData?.user_id,
      superadmin_timestamp: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
      dateFilter: paginationConfig?.formattedDate,
      type: paginationConfig?.feedType,
    })
      .then((res) => {
        setStats(res?.stats);

        setFeeds((prev) =>
          prev?.map((item_) =>
            item_?.id === item?.id
              ? {
                  ...item_,
                  superadmin_label: value === null ? null : value ? 1 : 0,
                  admin_user_name: userData?.name,
                  admin_user_id: userData?.user_id,
                }
              : item_
          )
        );
      })
      .finally(() => {
        setLocalLoading(null);
      });
  };

  const updateAdminShowFeedToParents = (e, item, value) => {
    e.target.classList.toggle("active");
    setLocalLoading(item?.id);
    fetchPost(BASE_URL + `${APIS.UPDATE_ADMIN_SHOW_FEED_TO_PARENT}`, "post", {
      id: item?.id,
      value,
      dateFilter: paginationConfig?.formattedDate,
      admin_parent_label_user_id: value ? userData?.user_id : null,
      admin_parent_label_username: userData?.name,
      admin_parent_label_timestamp: moment(Date.now()).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    })
      .then((res) => {
        setFeeds((prev) =>
          prev?.map((item_) =>
            item_?.id === item?.id
              ? {
                  ...item_,
                  admin_parent_label: value ? 1 : 0,
                  admin_parent_label_user_id: userData?.user_id,
                  admin_parent_label_username: userData?.name,
                }
              : item_
          )
        );
      })
      .finally(() => {
        setLocalLoading(null);
      });
  };

  const dislikeFeed = (item, value) => {
    setLocalLoading(item?.id);
    fetchPost(BASE_URL + `${APIS.DISLIKE_FEED}`, "post", {
      id: item?.id,
      value,
      dislike_label_user_id: value ? userData?.user_id : null,
      dislike_label_username: userData?.name,
      dislike_label_timestamp: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
    })
      .then((res) => {
        setFeeds((prev) =>
          prev?.map((item_) =>
            item_?.id === item?.id
              ? {
                  ...item_,
                  dislike_label: value ? 1 : 0,
                  dislike_label_user_id: userData?.user_id,
                  dislike_label_username: userData?.name,
                }
              : item_
          )
        );
      })
      .finally(() => {
        setLocalLoading(null);
      });
  };

  return (
    <>
      {feedId && (
        <Modal show={true} backdrop="static" keyboard={false}>
          <Modal.Body
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              maxHeight: "90vh",
            }}
          >
            <div className="d-flex">
              <i
                onClick={() => setFeedId(null)}
                className="bi pointer fs-20 bi-x ms-auto"
              ></i>
            </div>
            <Comments role={role} userData={userData} feedId={feedId} />
          </Modal.Body>{" "}
        </Modal>
      )}
      <div
        className={`position-fixed w-100 top-0 bg-${headerBg ? "dark" : ""}  ${
          !headerBg ? "border-bottom" : ""
        } py-3 trans-3s`}
        style={{ zIndex: 9999 }}
      >
        <div className="container w-100 d-flex align-items-center justify-content-between">
          {!type ? (
            <div className="btn-group">
              <button
                className={`btn border rounded shadow-none text-${
                  headerBg ? "white" : "dark"
                } white d-flex align-items-center`}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <p className="m-0">Alerts</p>{" "}
                <i
                  className={`bi bi-chevron-down text-${
                    headerBg ? "white" : "dark"
                  } ms-2`}
                ></i>
              </button>

              <div className="d-flex align-items-center">
                <ul className="dropdown-menu">
                  {FILTERS.map((item, index) => (
                    <li
                      className="dropdown-item pointer"
                      data-bs-toggle="dropdown"
                      onClick={() => {
                        if (paginationConfig?.feedType === item?.action) return;

                        setFeeds(null);

                        updatePaginationValues({
                          offset: 0,
                          feedType: item?.action,
                        });
                      }}
                      key={item?.action}
                    >
                      {item?.label}
                    </li>
                  ))}
                </ul>
                <p className="text-primary fs-15 m-0 fw-600 ms-2">
                  [{FEED_TYPE_LABELS[paginationConfig?.feedType]}]
                </p>
              </div>
            </div>
          ) : (
            <div>
              <p className="m-0 text-primary fs-20 fw-500">
                {CURR_LABEL_INDICATOR[type]}
              </p>
            </div>
          )}
          {schoolDetails && (
            <div>
              <p className="m-0 text-primary">{schoolDetails?.school_name}</p>
            </div>
          )}
          <div>
            {stats && feeds?.length > 0 && (
              <div>
                <p className="m-0 text-primary fs-15">
                  <span className="me-1">Approved</span>
                  <span className="fw-500">{totalApproved(stats)}</span>
                  <span className="me-1"> & Rejected</span>
                  <span className="fw-500">{totalRejected(stats)}</span>
                  <span className="mx-1">Out of</span>
                  <span className="fw-500">{totalVids(stats)}</span>
                  <span className="mx-1">Videos</span>
                </p>
                <p className="m-0 text-danger fs-15">
                  <span className="me-1">Videos Left</span>
                  <span className="fw-500">
                    {totalVids(stats) -
                      totalApproved(stats) -
                      totalRejected(stats)}
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container pt-5">
        <div className="row mt-5 flex-row g-1 g-md-3">
          {FILTER_CHIPS.map((item) => (
            <div key={item.name} className="col-auto">
              <p
                onClick={() =>
                  paginationConfig?.filterChipVal === item.value
                    ? {}
                    : mapFilterChipValues(item)
                }
                className={`pointer user-select-none fw-500 ff-inter px-3 m-0 fs-15 py-1 shadow-sm filter-chip ${
                  paginationConfig?.filterChipVal === item.value
                    ? "filter-chip-active fw-600"
                    : ""
                }`}
              >
                {item.name}
              </p>
            </div>
          ))}
        </div>

        <div className="row mt-2 flex-row g-1 g-md-3">
          {FILTER_CHIPS_NEW.map((item) => {
            const isSelected = paginationConfig[item?.key] === item?.clickValue;
            return (
              <div key={item.name} className="col-auto">
                <p
                  onClick={() => {
                    mapFilterNewChipValues(
                      item?.key,
                      isSelected ? item?.unclickValue : item?.clickValue
                    );
                  }}
                  className={`pointer user-select-none ff-inter px-3 m-0 fw-500 fs-15 py-1 shadow-sm filter-chip ${
                    isSelected ? "filter-chip-active fw-600" : ""
                  }`}
                >
                  {item.name}
                </p>
              </div>
            );
          })}
        </div>

        <div className="row m-0 p-0">
          <div className="col-12 col-md-4 mt-4 m-0 p-0">
            <Flatpickr
              options={{
                dateFormat: "J F Y",
              }}
              data-enable-time
              value={paginationConfig?.dateFilter}
              onChange={(date) => {
                const splitted = date.toString()?.split(" ");
                const mon = (new Date(date).getMonth() + 1)
                  ?.toString()
                  ?.padStart(2, "0");
                const year = splitted[3];
                const day = splitted[2];
                setFeeds(null);
                const formattedDate = `${year}-${mon}-${day}`;
                updatePaginationValues({
                  dateFilter: date,
                  formattedDate,
                  offset: 0,
                });
              }}
              className="m-0 shadow-sm form-control ff-inter fw-500 fs-14"
              style={{
                color: "#000",
                fontSize: 15,
              }}
            />
          </div>
        </div>

        <SuperAdminStats
          allStats={allStats}
          userData={userData}
          stats={stats}
          paginationConfig={paginationConfig}
        />

        <div className="row">
          <div className="mt-4 col-12 col-md-12 mx-auto ">
            {feeds &&
              feeds?.map((item, index) => (
                <div className="row border-bottom mb-4">
                  <div
                    key={item?.id + index.toString()}
                    className="col-12 col-md-6 position-relative pb-2"
                  >
                    <div className="mb-2 d-flex justify-content-between">
                      <p className="ff-inter text-secondary fw-500 fs-15">
                        {item?.datetime_string}
                      </p>

                      <p className="ff-inter text-primary fw-500 fs-15">
                        Camera:{" "}
                        {item?.camera_name
                          ? item?.camera_name
                          : item?.camera_no}
                      </p>
                    </div>

                    {/* <Moment>{correctTime(item?.addedOn)}</Moment>{" "} */}
                    <div
                      className="position-relative bg-dark"
                      style={{
                        height: height * 0.8,
                      }}
                    >
                      <div className="position-relative">
                        <VideoCard
                          height={height * 0.67}
                          item={item}
                          videoRef={videoRef}
                        />

                        {item?.id === localLoading && (
                          <div
                            className="position-absolute w-100 h-100 top-0 left-0 d-flex justify-content-center align-items-center ff-inter fw-400 text-white ff-16"
                            style={{
                              background: "#00000025",
                              zIndex: 998,
                            }}
                          >
                            <CircularProgress />
                          </div>
                        )}
                        <div>
                          <div
                            className="dislikeAnimation"
                            onClick={(e) =>
                              dislikeFeed(item, !item.dislike_label)
                            }
                          >
                            <SentimentDissatisfiedRoundedIcon
                              style={{
                                fontSize: 32,
                                color: !item.dislike_label
                                  ? "#ffffff99"
                                  : "#660000",
                              }}
                            />
                          </div>
                          <div
                            className={`heartAnimation ${
                              item.admin_parent_label ? "active" : ""
                            }`}
                            onClick={(e) =>
                              updateAdminShowFeedToParents(
                                e,
                                item,
                                !item.admin_parent_label
                              )
                            }
                          ></div>
                        </div>
                      </div>
                      <div
                        className="position-absolute w-100 m-0 left-0 bottom-0 row align-items-center pb-2"
                        style={{
                          height: height * 0.13,
                        }}
                      >
                        <div className="text-white text-center my-1 ff-inter fw-500 fs-16">
                          {item?.school_name}
                        </div>
                        <div className="col-6 d-flex align-items-center justify-content-center px-2">
                          <div className="px-2 w-100">
                            <button
                              onClick={() =>
                                updateTeacherFeed(
                                  item?.superadmin_label === 0 ? null : false,
                                  item
                                )
                              }
                              type="button"
                              className={`btn ${
                                REJECT_BTN[item?.superadmin_label]
                              } w-100 shadow-none trans-3s py-1 border-none`}
                            >
                              <p
                                className={`m-0 ff-inter fw-600 fs-15 text-white trans-3s`}
                              >
                                Reject
                              </p>
                            </button>
                          </div>
                        </div>
                        <div className="col-6 d-flex border-start align-items-center justify-content-center">
                          <div className="px-2 w-100">
                            <button
                              type="button"
                              onClick={() =>
                                updateTeacherFeed(
                                  item?.superadmin_label === 1 ? null : true,
                                  item
                                )
                              }
                              className={`btn ${
                                ACCEPT_BTN[item?.superadmin_label]
                              } w-100 py-1 shadow-none trans-3s border-none`}
                            >
                              <p
                                className={`m-0 ff-inter fw-600 fs-15 text-white trans-3s`}
                              >
                                Accept
                              </p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {debugMode && (
                      <>
                        <div className="row m-0 d-flex justify-content-between mt-3">
                          <div className="d-flex align-items-center col-6">
                            <i
                              onClick={() => shareFeedVid(item?.id)}
                              className="bi me-4 pointer fs-28 fc-cprimary bi-link-45deg"
                            ></i>

                            {isTabletOrMobile && (
                              <i
                                onClick={() => setFeedId(item?.id)}
                                className="bi me-4 pointer fs-24 fc-cprimary bi-chat-square-text"
                              ></i>
                            )}
                            <i
                              onClick={() => downloadVid(item)}
                              className="bi ms-2 me-4 pointer fs-24 fc-cprimary bi-download"
                            ></i>
                            <i
                              onClick={() => copyUUID(item?.id)}
                              className="bi ms-2 me-4 pointer fs-24 fc-cprimary bi-clipboard-check"
                            ></i>
                          </div>
                          <div className="d-flex align-items-center justify-content-end col-6">
                            {approved === item?.id && (
                              <i className="bi bi-check-square-fill fs-24 m-0 me-3 text-success ff-inter"></i>
                            )}

                            <button
                              onClick={() => approveFeedVid(item)}
                              type="button"
                              className={`btn btn-outline-secondary border shadow-none trans-3s border`}
                            >
                              <p
                                className={`m-0 ff-inter fw-500 fs-14 trans-3s`}
                              >
                                Mark for Review
                              </p>
                            </button>
                          </div>
                        </div>
                        {item?.admin_user_id && (
                          <p className="w-100 text-end mx-auto text-secondary w-100 user-select-none ff-inter fs-14 fw-400 m-0 mt-2">
                            Last Changed by{" "}
                            <span className="fc-cprimary">
                              {item?.admin_user_name}
                            </span>
                          </p>
                        )}
                        <div className="mb-2" />
                      </>
                    )}
                  </div>

                  {!isTabletOrMobile && (
                    <div
                      className="col-6 border-start pt-4 mt-4 rounded ms-auto"
                      style={{
                        height: height * 0.85,
                        overflow: "auto",
                      }}
                    >
                      <Comments
                        role={role}
                        userData={userData}
                        feedId={item?.id}
                      />
                    </div>
                  )}
                </div>
              ))}
            {feeds?.length === 0 && (
              <p className="m-0 text-secondary text-center">No Feed</p>
            )}
            {loading && (
              <div className="d-flex align-items-center justify-content-center">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(SuperAdminFeeds);

/* <div className="position-absolute w-100 m-0 left-0 py-3 bottom-0 row align-items-center video-controls">
<div className="col-6 d-flex align-items-center justify-content-center">
  <button
    onClick={() => updateTeacherFeed(false, item)}
    type="button"
    className={`btn ${
      REJECT_BTN[item?.superadmin_label]
    } border-none px-5 shadow-none trans-3s border border-white`}
  >
    <p
      className={`m-0 ff-inter fw-500 fs-14 text-white trans-3s`}
    >
      Reject
    </p>
  </button>
</div>
<div className="col-6 d-flex border-start align-items-center justify-content-center">
  <button
    type="button"
    onClick={() => updateTeacherFeed(true, item)}
    className={`btn ${
      ACCEPT_BTN[item?.superadmin_label]
    } border-none px-5 shadow-none trans-3s border border-white`}
  >
    <p
      className={`m-0 ff-inter fw-500 fs-14 text-white trans-3s`}
    >
      Accept
    </p>
  </button>
</div>
</div> */
