import { useContext, useEffect } from "react";
import "./App.css";
import Loader from "./components/loader/loader";
import AppContextProvider, { AppContext } from "./context/AppContext";
import { firebase } from "./firebase/config";
import { useHistory } from "react-router-dom";
import AppRoutes from "./routes/app-routes";
import { Chart, registerables } from "chart.js";
import { Toaster } from "react-hot-toast";

import annotationPlugin from "chartjs-plugin-annotation";
import { fetchPost } from "./utils/fetchPost";
import { APIS, BASE_URL } from "./constants/appConstants";
import { fetchGet } from "./utils/fetchGet";
import { encryptToken } from "./utils/appUtils";

Chart.register(...registerables, annotationPlugin);
const AppHelper = () => {
  const { loading, setLoading, user, userDbData, setValueByKey, setUser } =
    useContext(AppContext);
  const history = useHistory();
  useEffect(() => {
    setLoading(true);
    const unsub = firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        history.push("/login");
      } else {
        const tokenKey = process.env.REACT_APP_LOCAL_STORAGE_KEY;
        const token = await user.getIdToken(true);
        const encryptedToken = encryptToken(token);
        console.log(tokenKey, "tokenKey", token, encryptedToken);
        localStorage.setItem(tokenKey, encryptedToken);
        setUser(user?._delegate);
      }
      setLoading(false);
    });

    return unsub;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user && !userDbData) {
      setLoading(true);
      fetchPost(BASE_URL + `${APIS.GET_BACKOFFICE_USER}`, "post", {
        ...(user?.phoneNumber && { phone: user?.phoneNumber }),
        ...(user?.email && { email: user?.email }),
      })
        .then((res) => {
          setValueByKey("userDbData", res.userDbData);

          fetchGet(BASE_URL + `${APIS.GET_SCHOOL_LIST}`)
            .then((res) => {
              setValueByKey("schoolsList", res?.data?.schoolList);
            })
            .catch((err) => {})
            .finally(() => {
              setLoading(false);
            });
        })
        .finally(() => {});
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <div className="app">
      {loading && <Loader />}
      <AppRoutes />
    </div>
  );
};

function App() {
  return (
    <AppContextProvider>
      <Toaster />
      <AppHelper />
    </AppContextProvider>
  );
}

export default App;
