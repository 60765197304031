import React, { useEffect, useState } from "react";

import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

const annotation = {
  type: "line",
  borderColor: "black",
  borderDash: [],
  borderDashOffset: 0,
  borderWidth: 1,
  label: {
    enabled: true,
    content: (ctx) => "Average: " + 65,
    position: "end",
  },
  scaleID: "y",
  value: (ctx) => 65,
};
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    annotation: {
      annotations: {
        annotation,
      },
    },
  },
};

const getLabels = (startTime) => {
  let parsedTime = parseInt(startTime);

  const labels = [];
  let mins = 0;
  for (let i = 0; i < 8; i++) {
    labels.push(
      `${parsedTime?.toString()?.padStart(2, "0")}:${mins
        ?.toString()
        ?.padStart(2, "0")}`
    );

    if (i !== 0 && i % 3 === 0) {
      mins = 0;
      parsedTime++;
    } else {
      mins += 20;
    }
  }

  return labels?.filter((item) => !item?.includes("60"));
};

const getFakeData = (startTime, teacher) => {
  const labels = getLabels(startTime);
  return {
    labels,
    datasets: teacher
      ?.filter((item) => item !== "All")
      ?.map((item) => {
        const color = "#" + ((Math.random() * 0xffffff) << 0).toString(16);
        return {
          label: item,
          data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
          borderColor: color + "80",
          backgroundColor: color,
        };
      }),
  };
};

export const InteractionScore = ({ statDuration, startTime, teacher }) => {
  const [data, setData] = useState(getFakeData(startTime, teacher));

  useEffect(() => {
    setData(getFakeData(startTime, teacher));
  }, [statDuration, startTime, teacher]);

  return (
    <div className="row p-0">
      <div className="mt-2">
        <p className="ff-inter text-wmg fw-500 fs-20 mb-3">Interaction Score</p>
        <Line options={options} data={data} />
      </div>
    </div>
  );
};
