export const correctTime = (addedOn) => {
  const hours = new Date(addedOn)?.getHours();
  const mins = new Date(addedOn)?.getMinutes();

  //add hours
  const newDate = new Date(addedOn)?.setHours(hours + 6);
  return new Date(newDate)?.setMinutes(mins + 30);
};

export const correctTime2 = (addedOn) => {
  const hours = new Date(addedOn)?.getHours();
  const mins = new Date(addedOn)?.getMinutes();

  //add hours
  const newDate = new Date(addedOn)?.setHours(hours - 5);
  return new Date(newDate)?.setMinutes(mins - 30);
};

export const getHourMins = (mins_) => {
  const hours = parseInt(mins_ / 60)
    ?.toString()
    ?.padStart(2, "0");
  const mins = parseInt(mins_ % 60)
    ?.toString()
    ?.padStart(2, "0");

  if (hours === "00") return `${mins} Mins`;
  else if (mins === "00") return `${hours} Hours`;

  return `${hours} Hours & ${mins} Mins`;
};

export const getFormattedDate = (date) => {
  const splitted = date.toString()?.split(" ");
  const mon = (new Date(date).getMonth() + 1)?.toString()?.padStart(2, "0");
  const year = splitted[3];
  const day = splitted[2];

  return `${year}-${mon}-${day}`;
};
