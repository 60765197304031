import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { APIS, BASE_URL } from "../../constants/appConstants";
import { AppContext } from "../../context/AppContext";
import { firebase } from "../../firebase/config";
import { fetchPost } from "../../utils/fetchPost";

const provider = new firebase.auth.GoogleAuthProvider();

const USER_MEGS = {
  NUMBER_NOT_FORMATTED: {
    success: false,
    msg: "Please enter a valid number.",
  },
  OTP_SENT_SUCCESS: {
    success: true,
    msg: "Otp has been sent please check your mobile.",
  },
  OTP_SENT_FAILURE: {
    success: false,
    msg: "Please try again.",
  },
  OTP_VERIFY_SUCCESS: {
    success: true,
    msg: "Otp verified successfully.",
  },
  OTP_VERIFY_FAILURE: {
    success: false,
    msg: "Incorrect otp.",
  },
  NOT_AUTHORIZED: {
    success: false,
    msg: "You aren't authorized to login",
  },
  OTP_LENGTH: {
    success: false,
    msg: "Please enter 6 digits.",
  },
};

const Login = (props) => {
  const [showOtp, setShowOtp] = useState(false);
  const [userMsg, setUserMsg] = useState(null);
  const { setUser } = useContext(AppContext);
  const history = useHistory();
  // const { updateLoader } = props;
  // For Recaptcha
  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("re-c", {
      size: "invisible",
    });
  };
  const removeCaptcha = () => (document.querySelector("#re-c").innerHTML = "");

  const handleSubmit = (e) => {
    e.preventDefault();

    var formData = new FormData(e.target);
    const phoneNumber = formData.get("phoneNumber");
    const countryCode = formData.get("countryCode");
    const fullNumber = `+${countryCode}${phoneNumber}`;
    const otp = formData.get("otp");

    if (!showOtp) {
      fetchPost(BASE_URL + `${APIS.CHECK_BACKOFFICE_USER}`, "post", {
        phone: fullNumber,
      }).then((res) => {
        if (res?.success) {
          setUpRecaptcha();
          const verify = window.recaptchaVerifier;

          firebase
            .auth()
            .signInWithPhoneNumber(fullNumber, verify)
            .then((result) => {
              window.confirm = result;
              removeCaptcha();

              setShowOtp(true);

              setUserMsg(USER_MEGS.OTP_SENT_SUCCESS);
            })
            .catch((err) => {
              console.log(err);
              // setCorrectInp(false);
              setUserMsg(USER_MEGS.OTP_SENT_FAILURE);
              removeCaptcha();
            });
        } else {
          setUserMsg(USER_MEGS.NOT_AUTHORIZED);
          return;
        }
      });

      // if (phoneNumber?.length !== 10) {
      //   setUserMsg(USER_MEGS.NUMBER_NOT_FORMATTED);
      //   return;
      // }
      // updateLoader(true);

      // setUserMsg(USER_MEGS.NOT_AUTHORIZED);
    } else {
      if (otp?.length !== 6 || window?.confirm === null) {
        setUserMsg(USER_MEGS.OTP_LENGTH);
        return;
      }

      window.confirm
        .confirm(otp)
        .then((result) => {
          console.log("succes", result);
          history.push("/");
          setUserMsg(USER_MEGS.OTP_VERIFY_SUCCESS);
        })
        .catch((err) => {
          setUserMsg(USER_MEGS.OTP_VERIFY_FAILURE);
        });
    }

    // firebase
    //   .auth()
    //   .signInWithEmailAndPassword(email, password)
    //   .then((user) => {
    //     // console.log(user.user?._delegate?.email);
    //     history.push("/");
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setError(true);
    //   })
    //   .finally(() => {
    //     updateLoader(false);
    //   });
  };

  const signIn = () => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((data) => {
        const userData = data?.user?._delegate;

        fetchPost(BASE_URL + `${APIS.CHECK_BACKOFFICE_USER}`, "post", {
          email: userData?.email,
        }).then((res) => {
          if (res?.success) {
            localStorage.setItem("USER_DATA", JSON.stringify(userData));
            history.push("/");
            setUser(userData);
          }
        });

        console.log(data?.user?._delegate?.email);
      })
      .catch((error) => alert(error.message));
  };
  return (
    <div className="container pt-5">
      <div id="re-c"></div>

      <div className="row">
        <div className="col-11 col-md-4 mx-auto my-5">
          <h4 className="text-center mb-4">Login</h4>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="form-label">Country Code</label>
              <input
                required
                type="number"
                name="countryCode"
                className="form-control shadow-none"
                readOnly={showOtp}
              />
            </div>
            <div className="mb-4">
              <label className="form-label">Phone Number</label>
              <input
                required
                type="number"
                name="phoneNumber"
                className="form-control shadow-none"
                readOnly={showOtp}
              />
            </div>

            {showOtp && (
              <div className="mb-2">
                <label className="form-label">One Time Password</label>
                <input
                  required
                  type="number"
                  name="otp"
                  className="form-control shadow-none"
                />
              </div>
            )}

            <p
              style={{
                visibility: userMsg ? "visible" : "hidden",
              }}
              className={`m-0 text-${
                userMsg?.success ? "success" : "danger"
              } mb-2`}
            >
              {userMsg ? userMsg?.msg : "Hello"}
            </p>
            <button type="submit" className="btn btn-primary shadow-none">
              Login
            </button>
          </form>
        </div>

        <div
          className=" border-start col-11 col-md-6 mx-auto my-5"
          style={{
            height: 300,
          }}
        >
          <div className="h-100 d-flex align-items-center justify-content-center">
            <button className="btn btn-primary w-50" onClick={signIn}>
              Sign In With Google
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Login };
