import moment from "moment";
import { useEffect, useState } from "react";
import { APIS, BASE_URL } from "../../constants/appConstants";
import { fetchGet } from "../../utils/fetchGet";
import { fetchPost } from "../../utils/fetchPost";
import Comment from "../comment/comment";
import ThreeDots from "../../assets/svgs/three-dots.svg";

const Comments = ({ feedId, userData, role }) => {
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState(null);

  const isSuperAdmin = role === "SUPER_ADMIN";

  useEffect(() => {
    setLoading(true);
    fetchGet(
      BASE_URL +
        `/all-comments?feedId=${feedId}&filterStr=${
          isSuperAdmin ? "(-1,1,0)" : "(-1,0)"
        }`
    )
      .then((res) => {
        setComments(res?.data?.data?.comments);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedId]);

  const addComment = (e) => {
    e.preventDefault();
    setComments(null);
    const data = new FormData(e.target);
    const commentText = data.get("commentText");

    const internal_user = commentText
      ?.trim()
      ?.toLowerCase()
      ?.includes("@internal");

    setLoading(true);
    fetchPost(BASE_URL + APIS.ADD_COMMENT, "POST", {
      commentText,
      feedId,
      username: userData?.username ? userData?.username : "Unknown",
      name: userData?.name ? userData?.name : "Unknown",
      timestamp: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
      internal_user,
      filterStr: isSuperAdmin ? "(-1,1,0)" : "(-1,0)",
    })
      .then((res) => {
        setComments(res?.data?.comments);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="">
      {loading && (
        <div className="d-flex">
          <img
            width="50"
            className="mx-auto"
            alt="loader"
            height="50"
            src={ThreeDots}
          />
        </div>
      )}
      <div>
        {comments &&
          comments?.map((comment, index) => (
            <Comment index={index} comment={comment} />
          ))}
      </div>
      {!loading && comments?.length === 0 && (
        <div>
          <p className="p-0 text-center fs-14 text-secondary">
            No Previous Comments
          </p>
        </div>
      )}
      {!loading && (
        <form
          onSubmit={addComment}
          className="d-flex flex-column align-items-end"
        >
          <textarea
            required
            name="commentText"
            className="form-control shadow-none fs-16"
            placeholder="Please add a new comment"
            defaultValue={isSuperAdmin ? "@internal " : ""}
          ></textarea>

          <button type="sumbit" className="btn shadow-none mt-2">
            Post
          </button>
        </form>
      )}
    </div>
  );
};

export default Comments;
